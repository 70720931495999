/**
 * @generated SignedSource<<ac325e3f4623ed4f253f70e0558f404a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailAuthMutation$variables = {
  email: string;
  forceGuestToUserMerge?: boolean | null;
  inviteHash?: string | null;
  password: string;
};
export type EmailAuthMutation$data = {
  readonly register: {
    readonly deviceId: string | null;
    readonly token: string;
  } | null;
};
export type EmailAuthMutation = {
  response: EmailAuthMutation$data;
  variables: EmailAuthMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forceGuestToUserMerge"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inviteHash"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v4 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "forceGuestToUserMerge",
    "variableName": "forceGuestToUserMerge"
  },
  {
    "kind": "Variable",
    "name": "inviteHash",
    "variableName": "inviteHash"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "register",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmailAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "register",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4dc4e1b7c73627977e748e34c462d9c",
    "id": null,
    "metadata": {},
    "name": "EmailAuthMutation",
    "operationKind": "mutation",
    "text": "mutation EmailAuthMutation(\n  $email: String!\n  $password: String!\n  $inviteHash: String\n  $forceGuestToUserMerge: Boolean\n) {\n  register(email: $email, password: $password, inviteHash: $inviteHash, forceGuestToUserMerge: $forceGuestToUserMerge) {\n    token\n    deviceId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4737da97936ec9fb2bec8c1a36a4df8e";

export default node;
