import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { Bubble, BubbleWrap, ChatBubbleOwner } from "../../../components/assistant/ChatBubble";
import TypingDots from "../../../components/assistant/TypingDots";
import { htmlParser } from "tools/htmlParser";

export type FeedbackMessage = { text: string; owner: ChatBubbleOwner };

const MessageWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BubbleWithHtml = styled(Bubble)`
  white-space: normal;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li {
    margin: 7px 0;
    padding: 0;
    margin-bottom: 0.8em;
  }

  h1 {
    font-size: 1.1em;
    margin-bottom: 0.8em;
  }

  h2 {
    font-size: 1.1em;
    margin-bottom: 0.8em;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1em;
  }

  p {
    line-height: 1.4;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  li {
    line-height: 1.4;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  b {
    font-weight: bold;
  }
`;

type Props = {
  showTypingBubble: boolean;
  messages: FeedbackMessage[];
};

const MessageBubbles: React.FC<Props> = ({ showTypingBubble, messages }) => {
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <MessageWrapper>
      {messages.map((message, index) => (
        <BubbleWrap key={index} $owner={message.owner} ref={index === messages.length - 1 ? lastMessageRef : null}>
          {message.owner === "assistant" ? (
            <BubbleWithHtml $owner={message.owner}>{htmlParser(message.text)}</BubbleWithHtml>
          ) : (
            <Bubble $owner={message.owner}>{message.text}</Bubble>
          )}
        </BubbleWrap>
      ))}
      {showTypingBubble && (
        <BubbleWrap $owner="assistant">
          <Bubble $owner="assistant">
            <TypingDots />
          </Bubble>
        </BubbleWrap>
      )}
    </MessageWrapper>
  );
};

export default MessageBubbles;
