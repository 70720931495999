declare global {
  interface Window {
    fbq: (command: string, eventNameOrId?: string, parameters?: Record<string, any>) => void;
    _fbq?: (...args: any[]) => void;
  }
}

export const initMetaPixel = () => {
  if (process.env.NODE_ENV === "production") {
    if (!window.fbq) {
      (function (f: any, b: any, e: any, v: any, fbq?: any, t?: any, s?: any) {
        if (f.fbq) return;
        fbq = f.fbq = function (...args: any[]) {
          if (fbq.callMethod) {
            fbq.callMethod(...args);
          } else {
            fbq.queue.push(args);
          }
        };
        if (!f._fbq) f._fbq = fbq;
        fbq.push = fbq.push || [];
        fbq.loaded = true;
        fbq.version = "2.0";
        fbq.queue = [];
        t = b.createElement(e) as HTMLScriptElement;
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode?.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
    }

    // Initialize Meta Pixel
    window.fbq("init", "204769978998487");
    // Track the initial page view
    window.fbq("track", "PageView");
  }
};
