import styled, { css } from "styled-components/macro";
import { Trans } from "react-i18next";
import PriceBox from "./PriceBox";
import { useStoreIds } from "./subscriptionIds";
import { useViewerQuery } from "queries/viewerQuery";
import DoneIcon from "@mui/icons-material/Done";
import { isAndroid, isIos } from "tools/device";
import { isSubFor3months } from "sharedJs__generated/functions";
import { useSaleExpiration } from "./subscriptionHooks";
import { SALE } from "sharedJs__generated/constants";

const SubBoxWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 15px;
`;
const SubBoxHeader = styled.div`
  position: absolute;
  top: -24px;
  left: -2px;
  right: -2px;
  color: #333;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
`;
const SubBox = styled.div<{ isSelected?: boolean }>`
  position: relative;
  width: 33.3%;
  height: 119px;
  flex-shrink: 0;
  border: 1px solid #09d;
  border-width: 1px;
  box-shadow: ${({ isSelected, theme }) => (isSelected ? `0 0 0.5px 0.7px ${theme.duo.color.primary}` : "none")};
  background-color: ${({ isSelected, theme }) => (isSelected ? `${theme.duo.color.primary}11` : "transparent")};
  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding: 10px;
  justify-content: stretch;
  flex-direction: column;
  justify-content: center;
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    css`
      ${SubBoxHeader}  {
        background-color: ${({ theme }) => theme.duo.color.primary};
        border-radius: 8px;
        color: #fff;
        text-align: center;
        font-style: normal;
        font-weight: 500;
      }
    `}
  &:first-child {
    border-radius: 8px 0px 0px 8px;
    border-right-width: 0;
  }
  &:not(:first-child):not(:last-child) {
    border-radius: 0px 0px 8px 8px;
    margin-top: 17px;
    height: 136px;
    padding-bottom: 26px;
  }
  &:last-child {
    border-radius: 0px 8px 8px 0px;
    border-left-width: 0;
  }
`;
const SDoneIcon = styled(DoneIcon)`
  margin: 10px auto;
  color: ${({ theme }) => theme.duo.color.green};
`;
const Minus = styled.div`
  font-size: 12px;
  line-height: 1.4em;
  color: #999;
  margin: 20px 10px 0;
`;

const PriceButtons = ({ setPlan, plan }) => {
  const { storeIdYear, storeIdFamily, storeIdMonth } = useStoreIds();
  const { saleId } = useSaleExpiration();
  const {
    subscriptionFlags: { hasActiveMonthlySub, hasActiveYearlySub, isAndroidSubscription, isIosSubscription }
  } = useViewerQuery();

  const options = [
    ...(hasActiveYearlySub
      ? []
      : [
          {
            id: storeIdMonth,
            title: isSubFor3months(storeIdMonth) ? "3 Months" : "Monthly",
            bought: hasActiveMonthlySub
          }
        ]),
    {
      id: storeIdYear,
      title: "Yearly",
      highlight: saleId !== SALE.christmasFamily ? "Most Popular" : undefined,
      bought: hasActiveYearlySub
    },
    ...(storeIdYear === storeIdFamily // edge-case when user didn't finish tutorial and went to SubscriptionScreen
      ? []
      : [
          {
            id: storeIdFamily,
            title: "Family",
            highlight: "For 6 people"
          }
        ])
  ];

  return (
    <div>
      <SubBoxWrapper id="subPricesScrollId">
        {options.map((option, i) => (
          <SubBox
            key={option.id + i}
            isSelected={option.id.includes(plan) || plan.includes(option.id)}
            onClick={() => setPlan(option.id)}
          >
            {option.highlight && !option.bought && <Trans parent={SubBoxHeader}>{option.highlight}</Trans>}
            <Trans>{option.title}</Trans>
            {!option.bought ? <PriceBox plan={option.id} /> : <SDoneIcon />}
          </SubBox>
        ))}
      </SubBoxWrapper>

      {(hasActiveYearlySub || hasActiveMonthlySub) &&
        (isIos() && isIosSubscription ? (
          <Trans parent={Minus}>
            You will be charged the full price, and then Apple will refund the rest of your individual subscription.
          </Trans>
        ) : isAndroid() && isAndroidSubscription ? (
          <Trans parent={Minus}>The amount will be reduced by what you have already paid.</Trans>
        ) : (
          <> </>
        ))}
    </div>
  );
};

export default PriceButtons;
