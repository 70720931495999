import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Title = styled.div`
  font-size: 20px;
  margin: 30px 0;
  line-height: 1.3;
`;
const SImg = styled.img`
  width: 100%;
  display: block;
  margin: 0 auto;
`;

const SaleMainBlackFriday = () => {
  return (
    <>
      <Title>
        <Trans>Christmas is here!</Trans>
      </Title>

      <SImg src="https://cdn.duocards.com/mail/familyChristmas.png" />

      <Title>
        <Trans>Get a family subscription at the price of the individual subscription.</Trans>
      </Title>
    </>
  );
};

export default SaleMainBlackFriday;
