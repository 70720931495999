import React from "react";
import { HeaderLayout } from "components/header/Header";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Stack } from "@mui/material";
import HelpCenterSelect from "root/profile/HelpCenter/HelpCenterSelect";
import { helpCenterLevels } from "root/profile/HelpCenter/helpCenterDefinitions";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import OpenLastFeedbackButton from "../feedback/OpenLastFeedbackButton";

const Title = styled.h2`
  margin: 45px 0 20px 0;
`;
const SHeaderLayout = styled(HeaderLayout)`
  padding: 0 40px;
`;

type Props = {};
const HelpCenter: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <SHeaderLayout
      title={
        <Stack alignItems="center" direction="row" spacing={1}>
          <SupportAgentOutlinedIcon /> <span>{t("Help center")}</span>
        </Stack>
      }
      narrow
    >
      <Trans parent={Title}>How can we help you?</Trans>
      <HelpCenterSelect select={helpCenterLevels} />
      <OpenLastFeedbackButton />
    </SHeaderLayout>
  );
};

export default HelpCenter;
