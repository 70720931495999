import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button } from "@mui/material";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { FeedbackMessage } from "./MessageBubbles";
import { AIFeedbackFormMutation } from "./__generated__/AIFeedbackFormMutation.graphql";
import { useSetState, useGetState } from "components/ReduxProvider";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { FTextField } from "../../../styled/TextField";

const AIFeedbackQL = graphql`
  mutation AIFeedbackFormMutation($messages: [Message!]!, $relatedTo: String) {
    aiFeedback(messages: $messages, relatedTo: $relatedTo) {
      text
    }
  }
`;

export const FeedbackField = styled(FTextField)`
  textarea {
    min-height: 100px;
  }
`;

type Props = {
  relatedTo: { text: string } | undefined;
  onSubmitting: (isSubmitting: boolean) => void;
};

const AIFeedbackForm: React.FC<Props> = ({ relatedTo, onSubmitting }) => {
  const { t } = useTranslation();
  const [aiFeedbackMutation] = useMutation<AIFeedbackFormMutation>(AIFeedbackQL);

  const setLastFeedback = useSetState("lastFeedback");
  const lastFeedback = useGetState("lastFeedback");

  const messages = lastFeedback?.messages || [];

  const handleSubmit = (
    variables: { feedback: string },
    { setSubmitting, resetForm, setFieldValue }: FormikHelpers<{ feedback: string }>
  ) => {
    onSubmitting(true);
    setFieldValue("feedback", "");
    const resetState = () => {
      setSubmitting(false);
      resetForm();
      onSubmitting(false);
    };

    let messages: FeedbackMessage[] = lastFeedback?.messages || [];

    const addMessage = (data: FeedbackMessage) => {
      messages = [...messages, data];

      setLastFeedback({
        relatedTo: relatedTo?.text,
        messages,
        createdAt: Date.now()
      });
    };

    addMessage({ text: variables.feedback, owner: "user" });
    aiFeedbackMutation({
      variables: {
        messages: messages.map((message) => ({ content: message.text, role: message.owner })),
        relatedTo: relatedTo?.text || undefined
      },
      onCompleted: (data) => {
        addMessage({
          text: data?.aiFeedback?.[0]?.text || t("Unable to get AI response. Please try again."),
          owner: "assistant"
        });
        resetState();
      },
      onError: () => {
        addMessage({
          text: t("Unable to get AI response. Please try again."),
          owner: "assistant"
        });
        resetState();
      }
    });
  };

  return (
    <Formik initialValues={{ feedback: "" }} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <FeedbackField name="feedback" required label={t("Message")} autoFocus multiline disabled={isSubmitting} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isSubmitting}
            style={{ marginBottom: 20 }}
          >
            {messages.length === 0 ? t("Continue") : t("Ask AI (Instant)")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AIFeedbackForm;
