import React from "react";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { muiTheme } from "./theme";
import { useSelector } from "react-redux";
import { selectTheme } from "../components/ReduxProvider";
import { CssBaseline } from "@mui/material";

type Props = {
  children: React.ReactNode;
};

const ThemesProvider: React.FC<Props> = ({ children }) => {
  const themeState = useSelector(selectTheme);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <MuiThemeProvider theme={muiTheme(themeState)}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemesProvider;
