import { Trans } from "react-i18next";
import styled from "styled-components";
import { dividePrice } from "tools/price";
import { useSaleExpiration, useSubscriptions } from "./subscriptionHooks";
import { StoreId, useStoreIds } from "./subscriptionIds";
import { isSubFor3months } from "sharedJs__generated/functions";
import PriceSale from "./PriceSale";
import PriceExtra from "./PriceExtra";
import { SALE } from "sharedJs__generated/constants";

const MonthlyPrice = styled.div<{ isInSale: boolean }>`
  color: ${({ isInSale, theme }) => (isInSale ? theme.duo.color.green : "#09D")};
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
`;
const Price = styled.div<{ isInSale: boolean }>`
  color: ${({ isInSale, theme }) => (isInSale ? theme.duo.color.green : "auto")};
`;

const PriceBox = ({ plan }: { plan: StoreId }) => {
  const { subscriptions } = useSubscriptions();
  const { storeIdYear, storeIdFamily, storeIdMonth } = useStoreIds();
  const { saleId } = useSaleExpiration();

  if (!subscriptions) return <>N / A</>;

  const months = isSubFor3months(plan) ? 3 : 12;
  const haveMonthPrice = plan !== storeIdMonth || isSubFor3months(plan);
  const monthPrice = haveMonthPrice ? dividePrice(subscriptions[plan], months) || null : null;
  const isInSale = plan === storeIdYear || plan === storeIdFamily;

  return (
    <>
      {monthPrice && (
        <MonthlyPrice isInSale={isInSale}>
          {monthPrice} / <Trans>month</Trans>
        </MonthlyPrice>
      )}

      <PriceExtra plan={plan} />

      <Price isInSale={isInSale}>{subscriptions[plan]?.price}</Price>

      {(saleId === SALE.christmasFamily ? plan === storeIdFamily : plan === storeIdYear) && <PriceSale />}
    </>
  );
};

export default PriceBox;
