import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { TransferLemonIdMutation } from "./__generated__/TransferLemonIdMutation.graphql";
import { snackbar } from "tools/events";
// import { transferLemonIdMutation } from "./__generated__/transferLemonIdMutation.graphql";

export const transferLemonIdQL = graphql`
  mutation TransferLemonIdMutation($userId: String!, $email: String!) {
    transferLemonId(userId: $userId, email: $email) {
      id
      name
      email
    }
  }
`;

export const useTransferLemonId = () => {
  const [mutate] = useMutation<TransferLemonIdMutation>(transferLemonIdQL);
  return (userId: string, email: string) => {
    mutate({
      variables: { userId, email },
      onCompleted: (data) => {
        snackbar(`Lemon ID transferred successfully to ${data.transferLemonId.name} (${data.transferLemonId.email})`, {
          severity: "success"
        });
      }
    });
  };
};
