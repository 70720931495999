import { HeaderLayout } from "components/header/Header";
import React from "react";
import { TextField } from "styled/TextField";
import styled from "styled-components/macro";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { useDebounce, useEffectOnce } from "react-use";
import { AdminUsersSearchQuery } from "./__generated__/AdminUsersSearchQuery.graphql";
import Loader from "components/other/Loader";
import AdminUser from "./AdminUser";
import { useSearchParams } from "react-router-dom";

const Form = styled.form`
  padding: 20px;
`;

const searchUserQL = graphql`
  query AdminUsersSearchQuery($search: String!) {
    searchUsers(search: $search, first: 20) @connection(key: "Admin__searchUsers") {
      edges {
        node {
          id
          email
          name
          timezone
          appLang
          deviceId
          deviceOs
          createdBeforeSeconds
          lastLoginBeforeSeconds
          affiliate
          flags {
            name
            value
            body
            updated
          }
          xps {
            total
            currentStreak
          }
          subscriptions {
            transactionId
            timeToExpire
            platform
            storeId
            family
          }
          affiliateStats {
            payout
          }
          payouts {
            amount
          }
          lemonId
        }
      }
    }
  }
`;

const SForm = styled(Form)`
  padding: 0;
`;

type Props = {};

const AdminUsers: React.FC<Props> = () => {
  const [search, setSearch] = React.useState("");
  const [debouncedSearch, setDebouncedSearch] = React.useState("");
  const [searchParams] = useSearchParams();
  const { data: usersConnection, isLoading: isLoadingUsers } = useQuery<AdminUsersSearchQuery>(
    searchUserQL,
    { search: debouncedSearch },
    { skip: !debouncedSearch }
  );

  const users = usersConnection?.searchUsers.edges?.map((edge) => edge?.node) || [];

  // for payout reference link from email
  useEffectOnce(() => {
    setSearch(searchParams.get("search") || "");
  });

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    1000,
    [search]
  );

  function handleFormSubmit(e) {
    e.preventDefault();
    setDebouncedSearch(search);
  }

  return (
    <HeaderLayout title="Admin Users">
      <SForm onSubmit={handleFormSubmit}>
        <TextField
          label="Keyword"
          type="text"
          required
          name="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SForm>

      {isLoadingUsers && <Loader />}

      {!!users.length && users.map((user) => !!user && <AdminUser user={user} key={user.id} />)}
    </HeaderLayout>
  );
};

export default AdminUsers;
