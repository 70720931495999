import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 37px;
  margin-left: 40px;
  margin-right: 40px;
  height: 100%;
  overflow-y: auto;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const IconWrap = styled.div`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.duo.palette.blueMediumLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: ${({ theme }) => theme.duo.color.white};
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  font-size: 16px;
  margin-top: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.duo.palette.greyDark};
  margin-top: 4px;
`;
const googleStorageUrl = "https://imgs.duocards.com/onboarding/3m/";

const achievements: {
  iconPath: string;
  title: string;
  description: string;
}[] = [
  {
    iconPath: `${googleStorageUrl}speak.svg`,
    title: "You will gain more confidence",
    description: "Stress-free practice in speaking, reading, and listening"
  },
  {
    iconPath: `${googleStorageUrl}words.svg`,
    title: "Expanded vocabulary",
    description: "Common words and practical phrases"
  },
  {
    iconPath: `${googleStorageUrl}learn.svg`,
    title: "Develop a new learning habbit",
    description: "Smart reminders, fun challenges, and more"
  }
];

const AchievementPreview: React.FC = () => {
  return (
    <Wrap>
      {achievements.map((achievement, index) => (
        <Item key={index}>
          <IconWrap>
            <img src={achievement.iconPath} alt={`${achievement.title} Icon`} width={30} height={30} />
          </IconWrap>
          <TextWrap>
            <Title>
              <Trans>{achievement.title}</Trans>
            </Title>
            <Description>
              <Trans>{achievement.description}</Trans>
            </Description>
          </TextWrap>
        </Item>
      ))}
    </Wrap>
  );
};

export default AchievementPreview;
