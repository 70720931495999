import React from "react";
import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { userSaleQuery } from "queries/users/__generated__/userSaleQuery.graphql";
import { useViewerQuery } from "queries/viewerQuery";
import { SALE } from "sharedJs__generated/constants";

/**
 * TODO: refactor into viewerQuery
 */

const query = graphql`
  query userSaleQuery {
    userSale {
      id
      remaining
    }
  }
`;

/**
 * let's active this again once we have some sale to show
 */

export const useUserSale = (refreshing?: boolean) => {
  const { viewer } = useViewerQuery();
  const { data, isLoading, retry } = useQuery<userSaleQuery>(query, undefined, { skip: !viewer });
  const [queryRefetchKey, setQueryRefetchKey] = React.useState(0);
  const REFRESH_TIMEOUT = 12 * 60 * 60 * 1000; // 12h

  // let's refresh data from server once in 12h
  React.useEffect(() => {
    if (!refreshing) return;
    queryRefetchKey && retry();
    const timer = setTimeout(() => setQueryRefetchKey((prevKey) => prevKey + 1), REFRESH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [queryRefetchKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return { userSale: data?.userSale as { id: SALE; remaining: number } | undefined, isLoading, retry };
};
