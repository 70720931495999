import UndoIcon from "@mui/icons-material/Undo";
import FilterIcon from "../../icons/FilterIcon";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faRectangleVerticalHistory, faWebAwesome } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components/macro";
import { useMaxCardsToLearn } from "components/dialogs/useMaxCardsToLearn";
import { Trans } from "react-i18next";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useViewerQuery } from "queries/viewerQuery";

const Li = styled.div`
  padding: 8px 0;
  text-align: center;
  line-height: 1.4;
  opacity: 0;
  transition: opacity 1000ms;
  svg {
    margin: 0 7px;
    font-size: 30px;
  }
`;
const SubLi = styled.div`
  color: #ddd;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
`;
const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
`;
const Nowrap = styled.span`
  white-space: nowrap;
`;

const InternalAdOverviewBenefits = ({ visibleIndex }: { visibleIndex: number }) => {
  const TOLEARNMAX = useMaxCardsToLearn();
  const { hintWasSeen } = useViewerQuery();

  return (
    <>
      {hintWasSeen(TUTORIALDONE) ? (
        <Li style={{ fontSize: "120%" }} className={visibleIndex >= 1 ? "showing" : "hidden"}>
          <NotInterestedIcon />
          <Trans parent="div">Ad-free studying</Trans>
        </Li>
      ) : (
        <Li style={{ fontSize: "120%" }} className={visibleIndex >= 1 ? "showing" : "hidden"}>
          <FontAwesomeIcon icon={faWebAwesome} style={{ fontSize: "25" }} />
          <Trans parent="div">Custom Learning Plan</Trans>
        </Li>
      )}
      <Li style={{ fontSize: "110%" }} className={visibleIndex >= 2 ? "showing" : "hidden"}>
        <FontAwesomeIcon icon={faRectangleVerticalHistory} style={{ fontSize: "25" }} />
        <div>
          <Trans>Unlimited count of cards</Trans>
          <SubLi>
            <Trans>only {String(TOLEARNMAX)} "to learn" are for free</Trans>
          </SubLi>
        </div>
      </Li>
      <Li className={visibleIndex >= 3 ? "showing" : "hidden"}>
        <Icons>
          <FilterIcon style={{ width: 20, height: 20, fill: "white" }} />
          <UndoIcon />
          <FontAwesomeIcon icon={faPalette} style={{ fontSize: "25" }} />
          <RecordVoiceOverIcon />
        </Icons>
        <div>
          <Trans>Filtering, Undo button, Colours</Trans>, <Trans>Voices</Trans>,{" "}
          <Trans parent={Nowrap}>Unlimited study decks</Trans>
        </div>
      </Li>
    </>
  );
};

export default InternalAdOverviewBenefits;
