/**
 * @generated SignedSource<<c220435ca5d223a6fe57240d5c786d9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Message = {
  content: string;
  role: string;
};
export type AIFeedbackFormMutation$variables = {
  messages: ReadonlyArray<Message>;
  relatedTo?: string | null;
};
export type AIFeedbackFormMutation$data = {
  readonly aiFeedback: ReadonlyArray<{
    readonly text: string | null;
  } | null> | null;
};
export type AIFeedbackFormMutation = {
  response: AIFeedbackFormMutation$data;
  variables: AIFeedbackFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "messages"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "relatedTo"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "messages",
        "variableName": "messages"
      },
      {
        "kind": "Variable",
        "name": "relatedTo",
        "variableName": "relatedTo"
      }
    ],
    "concreteType": "AIResponse",
    "kind": "LinkedField",
    "name": "aiFeedback",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AIFeedbackFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AIFeedbackFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce0b3555b439de0f9041da8861e9864c",
    "id": null,
    "metadata": {},
    "name": "AIFeedbackFormMutation",
    "operationKind": "mutation",
    "text": "mutation AIFeedbackFormMutation(\n  $messages: [Message!]!\n  $relatedTo: String\n) {\n  aiFeedback(messages: $messages, relatedTo: $relatedTo) {\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "a92df9e68a73fa9d3d67cdaed77344a3";

export default node;
