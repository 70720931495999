import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import { TUTORIALDONE } from "../steps/Verdict";
import { logEvent } from "tools/analyticEvents";
import { LATE_REGISTRATION, MAIN } from "../../RootRouter.config";
import { useViewerQuery } from "../../../queries/viewerQuery";

const Wrap = styled.div`
  padding: 40px 0 10px;
  margin: auto;
`;
const Img = styled.img`
  width: 60px;
  height: 60px;
`;

type Props = {};

const SettingsDone: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const navigate = useNavigate();
  const { isGuest } = useViewerQuery();

  const message = isGuest ? (
    <Trans parent="strong">Don't lose your progress and create an account!</Trans>
  ) : (
    <Trans parent="strong">Memo has a quest for you!</Trans>
  );

  const redirect = () => {
    if (isGuest) {
      navigate(LATE_REGISTRATION.url());
    } else {
      navigate(MAIN.url(), { replace: true });
    }
  };

  return (
    <Wrap>
      <Img src="https://cdn.duocards.com/svg/confetti.svg" />

      <Trans parent="p">So far you are doing great!</Trans>

      <p>{message}</p>

      <p>&nbsp;</p>

      <Button
        variant="contained"
        onClick={() => {
          seeHint(TUTORIALDONE);
          logEvent("af_tutorial_completion");
          redirect();
        }}
      >
        <Trans>great, let's go</Trans>
      </Button>
    </Wrap>
  );
};

export default SettingsDone;
