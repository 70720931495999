/**
 * @generated SignedSource<<9fda076c32c93707e4e49a49e1f82106>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type File = {
  content: string;
  filename: string;
  type: string;
};
export type RelatedTo = {
  text: string;
};
export type SupportFeedbackModalMutation$variables = {
  attachment?: File | null;
  email: string;
  feedback: string;
  relatedTo?: RelatedTo | null;
};
export type SupportFeedbackModalMutation$data = {
  readonly feedback: boolean | null;
};
export type SupportFeedbackModalMutation = {
  response: SupportFeedbackModalMutation$data;
  variables: SupportFeedbackModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attachment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feedback"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "relatedTo"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attachment",
        "variableName": "attachment"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "feedback",
        "variableName": "feedback"
      },
      {
        "kind": "Variable",
        "name": "relatedTo",
        "variableName": "relatedTo"
      }
    ],
    "kind": "ScalarField",
    "name": "feedback",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportFeedbackModalMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "SupportFeedbackModalMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "3f28004f9971b221c57d93a812590c35",
    "id": null,
    "metadata": {},
    "name": "SupportFeedbackModalMutation",
    "operationKind": "mutation",
    "text": "mutation SupportFeedbackModalMutation(\n  $email: String!\n  $feedback: String!\n  $attachment: File\n  $relatedTo: RelatedTo\n) {\n  feedback(email: $email, feedback: $feedback, attachment: $attachment, relatedTo: $relatedTo)\n}\n"
  }
};
})();

(node as any).hash = "8f4a28de61752f65d80cbd7c67ca2053";

export default node;
