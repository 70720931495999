import React from "react";
import styled from "styled-components/macro";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { HeaderLayout } from "../../../components/header/Header";
import Button from "@mui/material/Button";
import { useCreateDeckMutation } from "../../../queries/decks/createDeck";
import Loader from "components/other/Loader";
import { useEditDeckMutation } from "../../../queries/decks/editDeck";
import { useDeleteDeckMutation } from "../../../queries/decks/deleteDeck";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { useDeviceLang } from "../../../hooks/deviceHooks";
import LangMenu from "../../../components/LangMenu/LangMenu";
import LangButton from "../../../components/LangMenu/LangButton";
import DeckWarn from "./DeckWarn";
import { DECK } from "../MainRouter";
import Toggler from "components/other/Toggler";
import { useLogout } from "../../../queries/users/logout";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { MAIN } from "../../RootRouter.config";
import { ConditonalWrap } from "tools/ConditionalWrap";

export const T_NATIVELANG = "Your native Language";
export const T_LANGLEARNING = "Language you want to learn";
export const T_DIFFLANGS = "Languages have to be different";
export const T_ENGLISH =
  "Do you understand English? If yes, we recommend to switch to English so you get more and better quality learning materials.";

const SForm = styled.form<{ $inOnboarding?: boolean }>`
  padding: ${({ $inOnboarding }) => ($inOnboarding ? "0 30px" : "30px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  width: ${({ $inOnboarding }) => ($inOnboarding ? "100%" : "auto")};
`;
const DelButton = styled(Button)`
  float: right;
  padding: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
`;
const Label = styled.div<{ light?: boolean }>`
  font-weight: ${({ light }) => (light ? "normal" : "bold")};
  margin: 10px;
`;
const SButton = styled(Button)`
  margin: 15px 0;
  padding: 10px 0;
`;
const SelectWrapper = styled.div`
  position: relative;
  margin: 0 0 18px;
`;
const SwitchButton = styled(Button)`
  margin: 0 auto;
  display: block;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;

const SDeckWarn = styled(DeckWarn)<{ $inOnboarding?: boolean }>`
  margin: ${({ $inOnboarding }) => ($inOnboarding ? "0 0 18px" : "inherit")};
`;

export type Values = { front: string; back?: string };

type Props = {
  isStep1Done?: boolean;
  inOnboarding?: boolean;
  editId?: string;
  onCanSubmitChange?: (canSubmit: boolean, values: Values) => void;
};

const DeckForm: React.FC<Props> = ({
  onCanSubmitChange,
  isStep1Done = undefined,
  inOnboarding = false,
  editId = undefined
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get(DECK.idParam) || editId;
  const { deviceLang } = useDeviceLang();
  const { decks, deck } = useViewerDecks();
  const createDeck = useCreateDeckMutation();
  const editDeck = useEditDeckMutation();
  const deleteDeck = useDeleteDeckMutation();
  const { t } = useTranslation();
  const [asked, setAsked] = React.useState(false);
  const [step1Done, setStep1Done] = React.useState(!!id || !!isStep1Done);
  const logout = useLogout();
  const { hintWasSeen, viewer } = useViewerQuery();
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    if (isStep1Done !== undefined) setStep1Done(isStep1Done);
  }, [isStep1Done]);

  const getBackFromFront = (front: string) =>
    front === "en" && deviceLang === "en" && viewer?.defaultLanguages?.native
      ? viewer?.defaultLanguages?.native
      : front !== "en"
        ? "en"
        : front !== deviceLang
          ? deviceLang
          : undefined;

  let init: Values = { front: deck?.front || "", back: deck?.back || "" };
  if (!id) {
    init.front = (viewer?.defaultLanguages?.target !== deviceLang && viewer?.defaultLanguages?.target) || "en";
    init.back = getBackFromFront(init.front);
  }
  const [values, setValues] = React.useState<Values>(init);
  React.useEffect(() => {
    // reinitialize in case defaultLanguages are changed, which is happening after registration
    if (init.back !== values.back || init.front !== values.front) setValues(init);
  }, [init.front, init.back]); // eslint-disable-line react-hooks/exhaustive-deps

  const warning = !id && values.back !== "en" && values.front !== "en" ? T_ENGLISH : undefined;
  const undefinedBack = step1Done && !values.back ? true : false;
  const frontError =
    !id && decks?.some((d) => d.front === values.front)
      ? "You already have this language. If you're trying to categorize your words, use sets of cards."
      : undefined;

  const selectionInvalid = (!!id && values.back === init.back) || !!frontError || undefinedBack;

  React.useEffect(() => {
    onCanSubmitChange?.(!selectionInvalid, values);
  }, [selectionInvalid, onCanSubmitChange, values]);

  if (!init || submitted) return <Loader />;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!step1Done) {
      setStep1Done(true);
    } else {
      !id
        ? createDeck({ front: values.front, back: values.back || "" })
        : editDeck({ id, back: values.back as string });
      setSubmitted(true);
    }
  };

  const handleNative = !id
    ? undefined
    : () => {
        if (!asked && !inOnboarding) {
          setAsked(true);
          return window.confirm(t("Your existing cards will stay in the previous language. Continue?"));
        }
        return true;
      };

  return (
    <ConditonalWrap
      condition={!inOnboarding}
      wrap={(children) => (
        <HeaderLayout
          noBack={deck ? false : true}
          onBack={() => navigate(`${MAIN.url()}`)}
          title={id ? t("Edit deck") : t("Create new deck")}
          narrow
        >
          {children}
        </HeaderLayout>
      )}
    >
      <>
        <SForm autoComplete="off" onSubmit={handleSubmit} $inOnboarding={inOnboarding}>
          <SelectWrapper>
            <Label light={!!id}>{t(T_LANGLEARNING)}</Label>
            <LangMenu
              title={<LangButton lang={values.front} />}
              disabled={!!id}
              onSelect={(front) => setValues({ front, back: getBackFromFront(front) })}
            />
            {frontError && <SDeckWarn $inOnboarding={inOnboarding} warning={frontError} />}
          </SelectWrapper>

          <Toggler show={step1Done}>
            <SelectWrapper>
              <Label light={!!id}>{t(T_NATIVELANG)}</Label>
              <LangMenu
                onClick={handleNative}
                onSelect={(back) => setValues({ front: values.front, back })}
                skipLang={values.front}
                value={values.back}
                title={<LangButton lang={values.back} style={warning ? { borderColor: "orange" } : undefined} />}
              />
              {warning && <SDeckWarn $inOnboarding={inOnboarding} warning={warning} />}
            </SelectWrapper>
          </Toggler>

          {!inOnboarding && (
            <SButton
              color="primary"
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              disabled={selectionInvalid}
            >
              {!step1Done ? t("Next") : deck ? t("Save") : t("Continue")}
            </SButton>
          )}
          {id && !inOnboarding && <DelButton onClick={() => deleteDeck({ id })}>{t("Delete deck")}</DelButton>}
        </SForm>
        {!hintWasSeen(TUTORIALDONE) && !inOnboarding && (
          <SwitchButton onClick={() => logout()} size="small">
            <Trans>Switch account</Trans>
          </SwitchButton>
        )}
      </>
    </ConditonalWrap>
  );
};

export default DeckForm;
