import { Trans } from "react-i18next";
import styled from "styled-components";
import { Top } from "./InternAdOverview";

const Benefit = styled.div<{ show: boolean }>`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 500ms;
`;
const Text = styled.div`
  font-size: 110%;
`;

const InternalAdFirstPaywallSale = ({ visibleIndex }: { visibleIndex: number }) => {
  return (
    <>
      <Benefit show={visibleIndex >= 1}>
        <Trans parent={Top}>7 days free</Trans>
      </Benefit>
      <div />
      <Benefit show={visibleIndex >= 2}>
        <Trans parent={Text}>Now with a discount for newly registered</Trans>
        <Benefit show={visibleIndex >= 3}>
          <Top>-40%</Top>
        </Benefit>
      </Benefit>
      <div />
    </>
  );
};

export default InternalAdFirstPaywallSale;
