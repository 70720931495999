import styled, { css } from "styled-components/macro";
import { Gesture } from "./Card";

const SideClick = styled.button<{ left?: boolean }>`
  position: absolute;
  top: 0;
  width: 45px;
  height: 100%;
  z-index: 10;
  border: none;
  background: transparent;
  cursor: pointer;
  ${({ left }) =>
    left
      ? css`
          left: -35px;
        `
      : css`
          right: -35px;
        `}
`;

const SideClicks = ({ onGesture }: { onGesture: (gesture: Gesture) => void }) => {
  return (
    <>
      <SideClick
        aria-label="Knew it"
        onClick={(e) => {
          onGesture("know");
          e.stopPropagation();
        }}
      />
      <SideClick
        aria-label="Didn't know it"
        onClick={(e) => {
          onGesture("unknow");
          e.stopPropagation();
        }}
        left
      />
    </>
  );
};

export default SideClicks;
