import { Trans } from "react-i18next";
import Snowfall from "react-snowfall";
import styled from "styled-components";

const Tree = styled.span`
  font-size: 70px;
  position: relative;
  z-index: 1;
`;
const Present = styled.span`
  position: relative;
  font-size: 30px;
  left: 25px;
  top: 25px;
`;
const Present2 = styled.span`
  position: relative;
  font-size: 20px;
  left: -30px;
  top: 20px;
`;
const Emojis = styled.div`
  margin: 20px 10px;
`;
const Text = styled.div<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 20px;
  line-height: 1.4;
`;

const InternalAdOverviewChristmas = ({ visibleIndex }: { visibleIndex: number }) => {
  return (
    <>
      <Snowfall snowflakeCount={20} />

      <Text bold>
        <Trans>Christmas is here!</Trans>
      </Text>

      <Emojis>
        <Present>🎁</Present>
        <Tree>🎄</Tree>
        <Present2>🎁</Present2>
      </Emojis>

      <Text>
        <Trans>Get a family subscription at the price of the individual subscription.</Trans>
      </Text>
    </>
  );
};

export default InternalAdOverviewChristmas;
