import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import { useSubscriptions } from "./subscriptionHooks";
import React from "react";
import { isApp } from "tools/device";
import { useLemonLink } from "./LemonLink";
import { useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";

type Props = { overrideManage?: () => void };

export const ManageSubscriptionButton: React.FC<Props> = () => {
  const { store } = useSubscriptions();
  const {
    subscriptionFlags: { isLemonSubscription, isSubscriptionCancelled }
  } = useViewerQuery();
  const setOrderOpened = useSetState("orderOpened");
  const { redirectToLemon } = useLemonLink();

  const handleClick = () => {
    if (isLemonSubscription && isApp() && !isSubscriptionCancelled) {
      redirectToLemon({
        variables: { type: "portal" },
        onCompleted: () => {
          setOrderOpened(undefined);
        }
      });
    } else {
      store.manageSubscriptions();
    }
  };

  return (
    <Button color="primary" onClick={handleClick}>
      <Trans>Manage Subscriptions</Trans>
    </Button>
  );
};
