import React from "react";
import { Trans } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { Button, Slide } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useSetFlag } from "queries/users/setFlag";
import { GoalFlag } from "sharedJs__generated/constants";
import { useViewerQuery } from "queries/viewerQuery";
import DialogFullWindow from "components/other/DialogFullWindow";
import { useEvent } from "react-use";
import { EVENT } from "tools/events";

const Wrap = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 25px 30px;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  font-size: 120%;
  margin: 10px 20px 5px;
  text-align: center;
`;
const List = styled.div`
  box-shadow: "0 0 16px #def";
  margin: "0 -15px";
  svg {
    margin: 0 10px 0 0;
  }
`;
const SImg = styled.img`
  padding: 0 20px 20px;

  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    max-width: 500px;
    margin: 0 auto;
  }
`;
const Row = styled.div<{ selected?: boolean }>`
  border: 1px solid white;
  padding: 17px 20px;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  &:first-of-type {
    border-top: 1px solid white;
  }
  svg {
    font-size: 20px;
  }
  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.duo.color.primary};
      border: 1px solid #0099dd !important;
      border-radius: 4px;
    `}
`;
const ButtonWrap = styled.div`
  padding: 5px 30px;
`;

type Props = {};

const Goal: React.FC<Props> = () => {
  const { getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const [open, setOpen] = React.useState(false);

  const openFn = React.useCallback(() => setOpen(true), []);
  useEvent(EVENT.openGoalDialog, openFn);

  const titles = [
    { value: "3", text: "I will learn 3 days in row" },
    { value: "7", text: "I will learn 7 days in row" },
    { value: "14", text: "I will learn 14 days in row" },
    { value: "0", text: "I don't want any goals" }
  ];
  const goal = getFlag("myGoal")?.value;

  return (
    <DialogFullWindow open={open} TransitionComponent={Slide}>
      <Wrap>
        <div />
        <SImg src="https://storage.googleapis.com/cdn.duocards.com/img/goal.png" alt="Goal" className="nofilter" />

        <Title>
          <Trans>Set a goal for yourself</Trans>
        </Title>

        <List>
          {titles.map(({ value, text }) => (
            <Row key={value} selected={value === goal} onClick={() => setFlag({ name: "myGoal", value } as GoalFlag)}>
              {value === goal ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
              <Trans>{text}</Trans>
            </Row>
          ))}
        </List>

        <ButtonWrap>
          <Button
            disabled={goal === undefined}
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setOpen(false)}
          >
            <Trans>Continue</Trans>
          </Button>
        </ButtonWrap>
        <div />
      </Wrap>
    </DialogFullWindow>
  );
};

export default Goal;
