import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useGetState } from "components/ReduxProvider";
import { FEEDBACK } from "../ProfileRouter.config";
import { isRecentFeedback } from "./Feedback";

const OpenLastFeedbackButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lastFeedback = useGetState("lastFeedback");

  // Don't show button if no feedback or if feedback is too old
  if (!lastFeedback?.relatedTo || !lastFeedback?.createdAt) {
    return null;
  }

  const isRecent = isRecentFeedback(lastFeedback.createdAt);
  if (!isRecent) {
    return null;
  }

  const handleClick = () => {
    navigate(FEEDBACK.url(), {
      state: {
        relatedTo: {
          text: lastFeedback.relatedTo
        }
      }
    });
  };

  return (
    <Button variant="text" fullWidth color="primary" onClick={handleClick}>
      {t("Continue last chat")}
    </Button>
  );
};

export default OpenLastFeedbackButton;
