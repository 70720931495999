import { graphql } from "babel-plugin-relay/macro";
import { useDBLogMutation } from "./__generated__/useDBLogMutation.graphql";
import { useMutation } from "relay-hooks";
import { useCallback } from "react";
import { Serializable } from "../sharedJs__generated/constants";

const dblog = graphql`
  mutation useDBLogMutation($name: String!, $text: String!, $body: String, $ttlDays: Int) {
    dblogMutation(name: $name, text: $text, body: $body, ttlDays: $ttlDays)
  }
`;

/*
Usage:
    const dblog = useDBLog();
    dblog("name", "text");
    dblog("name", "text", { value: "hello" });
    dblog("name", "text", ["string", 10]);
    dblog("name", "text", undefined, 20);
    dblog("name", "text", undefined, Infinity);
 */
export function useDBLog() {
  const [doDblog] = useMutation<useDBLogMutation>(dblog);

  const sendDbLog = useCallback(
    (name: string, text: string, body?: Serializable<object>, ttlDays: number = 90) => {
      if (ttlDays === Infinity) ttlDays = 12 * 30 * 100; // 100 years
      doDblog({
        variables: {
          name,
          text,
          body: JSON.stringify(body),
          ttlDays
        }
      });
      if (process.env.NODE_ENV !== "production") {
        console.info("DBLOG", { name, text, body, ttlDays });
      }
    },
    [doDblog]
  );

  return sendDbLog;
}
