import React from "react";
import styled from "styled-components/macro";
import { Button, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

const AlertActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
`;

const HelpfulPrompt: React.FC<{ onYes: () => void; onNo: () => void }> = ({ onYes, onNo }) => {
  const { t } = useTranslation();
  return (
    <Alert
      severity="info"
      icon={false}
      style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <p>{t("Did you find the information you were looking for?")}</p>
      <AlertActions style={{ justifyContent: "center", width: "100%" }}>
        <Button variant="text" color="primary" onClick={onYes} startIcon={<ThumbUpIcon />}>
          {t("Yes")}
        </Button>
        <Button variant="text" color="primary" onClick={onNo} startIcon={<ThumbDownIcon />}>
          {t("No")}
        </Button>
      </AlertActions>
    </Alert>
  );
};

export default HelpfulPrompt;
