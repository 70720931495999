import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components/macro";
import { DelSign, KnownSign, UnknowSign } from "./Signs";
import { useDispatch, useSelector } from "react-redux";
import { nextTutorialTapPhase, selectInviteDialog, selectTutorialTapPhase } from "components/ReduxProvider";
import { useTtsQuery } from "queries/ttsQuery";
import { useSeeHint } from "components/hint/Hint";
import { useViewerQuery, useViewerDecks } from "queries/viewerQuery";
import { useOpenAdsDialog } from "components/ads/adStack";
import { genderStyle } from "tools/genderStyle";
import { useDebounce } from "react-use";
import Speech from "components/speech/Speech";
import SpeechHintTooltip, { LONGPRESSHINT2 } from "components/picker/SpeechHintTooltip";
import Contextor from "root/library/source/contextor/Contextor";
import { isAndroid, isIos } from "tools/device";
import { setLearnSwipesOpacity } from "./LearnArrows";
import { loopQuery_loop$data } from "queries/decks/__generated__/loopQuery_loop.graphql";
import CardGrammarOpener from "root/main/learn/CardGrammarOpener";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { canTts } from "queries/tts/ttsTools";
import CardHintDelete, { CARDDELHINT } from "root/main/learn/cardHintsInline/CardHintDelete";
import { TapPhases, TAPTUTORIALDONE } from "root/tutorial/steps/TapCard";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import HintTooltip from "components/other/HintTooltip";
import Pronunciation from "../components/Pronunciation";
import UpSize from "root/main/components/UpSize";
import FlatImg from "components/other/FlatImg";
import CardSourceIcons from "root/main/learn/CardSourceIcons";
import { useGetState } from "components/ReduxProvider";
import { useKeyMy } from "hooks/useKeyMy";
import { includesHighlighter, parser } from "tools/strings";
import { useGetGrammar } from "components/GrammarDictionary/getGrammar";
import CardEditButton from "../card/CardEditButton";
import { useTranslateQuery } from "queries/translateQuery";
import Loader from "components/other/Loader";
import { Collapse, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTranslate } from "@awesome.me/kit-55349eb283/icons/kit/custom";
import DialogWindow from "components/other/DialogWindow";
import { useWindowSize } from "hooks/useWindowSize";
import CantListenNow from "./CantListenNow";
import { useVoiceOffTimer } from "hooks/useVoiceOffTimer";
import CardDebug from "./CardDebug";
import SideClicks from "./SideClicks";
const MOVE = 50;
export const CARD_TRANS = 260; // ms

type FWProps = { index: number; moving: boolean; putBelow?: boolean };
export const CardFlipWrap = styled.div<FWProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${({ putBelow, index }) => (putBelow ? 5 : index === 0 ? 12 : 10)};
  top: 0;
  left: 0;
  transition: ${({ moving, index }) => (moving || index > 0 ? "all 0s" : `all ${CARD_TRANS}ms`)};
  perspective: 1000px;
  transform: ${({ index }) => (index === 2 ? "rotate(4deg)" : index === 3 ? "rotate(-3deg)" : "none")};
  /* user-select: none; */
`;
const Flipper = styled.div<{ turned: boolean; transition: boolean }>`
  width: 100%;
  height: 100%;
  transition: ${({ transition }) => (transition ? 0.5 : 0)}s;
  transform-style: preserve-3d;
  position: relative;
  /* on ios (>16.4) and safari browser there is an issue which can be fixed by this */
  transform: ${({ turned }) => (turned ? `rotateY(${isAndroid() ? 180 : 179.9}deg)` : "none")};
`;

const SCantListenNow = styled(CantListenNow)`
  position: absolute;
  bottom: 30px;
`;

export const CardFront = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  transform: rotateY(0deg); /* for firefox 31 */
  border-radius: ${({ theme }) => theme.duo.borderRadius.normal};
  border: ${({ theme }) => theme.duo.border.grey};
  border-right: 1px solid #d6d6d3;
  border-bottom: 1px solid #d6d6d3;
`;
export const CardBack = styled(CardFront)`
  transform: rotateY(180deg);
`;
const Cont = styled.div<{ blankForTurning?: boolean }>`
  flex-basis: content;
  margin: auto 25px;
  transition: 1s opacity cubic-bezier(1, 0, 1, 0);
  opacity: ${({ blankForTurning }) => (blankForTurning ? 0 : 1)};
`;
const Phrase = styled.div`
  position: relative;
  font-size: 140%;
  margin: 12px auto;
  width: fit-content;
  line-height: 1.45em;
`;
const Label = styled.div`
  margin: 18px auto 12px;
  opacity: 0.7;
  position: relative;
  width: fit-content;
`;
const Hint = styled.div<{ $isHintOverflowing: boolean }>`
  position: relative;
  margin: ${({ $isHintOverflowing }) => ($isHintOverflowing ? "15px -15px 0" : "15px -15px")};
  padding: 0 15px;
  p {
    margin: 8px 0;
  }
  ${({ $isHintOverflowing }) =>
    $isHintOverflowing &&
    css`
      overflow: hidden;
      max-height: 80px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      }
    `}
`;

const Blurred = styled.span<{ blurred: boolean }>`
  &,
  * {
    filter: blur(${({ blurred }) => (blurred ? 5 : 0)}px);
  }
  transition: filter 0.2s;
`;
const SVisibilityIcon = styled(VisibilityIcon)`
  position: absolute;
  margin: auto;
  left: 10px;
  right: 0;
  top: 3px;
  bottom: 0;
  font-size: 27pt;
  color: #ccc;
  background: #fff3;
  border-radius: 100px;
  inset: 0px 0px 0px 10px;
  filter: drop-shadow(0 0 4px white);
`;
const Icons = styled.div<{ $isHintOverflowing: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  .MuiIconButton-root {
    padding: 7px;
    margin: 0px 7px;
  }
  .MuiInputAdornment-root {
    margin-left: 0;
  }
  svg {
    width: 18px;
    height: 18px;
    color: ${({ theme }) => theme.duo.color.lightBlue};
  }
`;
const TranslateLoader = styled(Loader)`
  padding: 9px;
  margin: 0px 8px;
  width: inherit;
`;
const Example = styled.span`
  font-size: 85%;
  & strong {
    color: ${({ theme }) => theme.duo.color.textAccent};
  }
`;
const Separator = styled.hr`
  margin: 22px 10px;
`;
const Translation = styled.div`
  color: ${({ theme }) => theme.duo.color.textGrey};
  line-height: 1.5;
  margin: -4px auto 10px;
  font-size: 13px;
`;
const TranslateButton = styled(IconButton)`
  && svg {
    width: 20px !important;
    height: 20px;
  }
`;
const TranslationDialog = styled.div`
  margin: -10px 5px 20px;
`;

export type Gesture = "know" | "unknow" | "delete";
type Props = {
  card: loopQuery_loop$data["loop"][0];
  unBlurred: boolean;
  onUnblur: VoidFunction;
  lang: string;
  known?: boolean;
  onGesture: (gesture: Gesture) => void;
  index: number;
  turned: boolean;
  setTurned: (turned: boolean) => void;
  blankForTurning?: boolean;
  putBelow?: boolean;
  silence: boolean;
  isLast?: boolean;
};
const Card: React.FC<Props> = React.memo((p) => {
  const [startPos, setStartPos] = React.useState({ x: 0, y: 0 });
  const said = React.useRef(false);
  const [contextorSelection, setContextorSelection] = React.useState<string>();
  const contextSelectionDebounced = React.useRef<any>();
  // prettier-ignore
  useDebounce(() => { contextSelectionDebounced.current = contextorSelection; }, 100, [contextorSelection]);
  const voiceOff = useGetState("voiceOff");
  const cardRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const knowRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const unknowRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const contRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const delRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const hintRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const tts = useTtsQuery(p.lang);
  const seeHint = useSeeHint();
  const { hintWasSeen, viewer } = useViewerQuery();
  const { deck } = useViewerDecks();
  const openAdsDialog = useOpenAdsDialog();
  const tapPhase = useSelector(selectTutorialTapPhase);
  const dispatch = useDispatch();
  const inviteDialog = useSelector(selectInviteDialog);
  const loopFilter = useGetState("loopFilter");
  const grammar = useGetGrammar(p.card.front);
  const { height } = useWindowSize();
  const [translatationOpened, setTranslatationOpened] = React.useState(false);
  const [isHintDialogOpen, setIsHintDialogOpen] = useState(false);
  const [overFlowing, setIsOverFlowing] = useState<"hint" | "translation" | null>(null);
  const { translate: translation, isLoading: translateIsLoading } = useTranslateQuery(
    translatationOpened ? { from: p.card.hint, fromLang: deck?.front, toLang: deck?.back } : undefined
  );
  const hintTapWasSeen = hintWasSeen(TAPTUTORIALDONE) || hintWasSeen(TUTORIALDONE);

  useVoiceOffTimer();

  React.useEffect(() => {
    if (
      tts &&
      !said.current &&
      !voiceOff &&
      p.index === 0 &&
      p.card.flipped === p.turned &&
      !openAdsDialog &&
      !p.silence &&
      !inviteDialog
    ) {
      tts({ text: p.card.front });
      said.current = true;
    }
    if (p.index !== 0 && said.current) {
      said.current = false;
    }
    if (!overFlowing && hintRef.current && contRef.current && cardRef.current) {
      if (contRef.current?.clientHeight > cardRef.current?.clientHeight) {
        setIsOverFlowing("hint"); // hint won't fint on the card
      } else if (hintRef.current?.clientHeight + contRef.current?.clientHeight > cardRef.current?.clientHeight) {
        setIsOverFlowing("translation"); // hint fits, but translation not anymore
      }
    }
  }, [p, voiceOff, tts, openAdsDialog, hintWasSeen, inviteDialog, height, overFlowing]);

  const getX = (e) => e.pageX || (e.touches?.[0] || e.changedTouches?.[0])?.pageX;
  const getY = (e) => e.pageY || (e.touches?.[0] || e.changedTouches?.[0])?.pageY;
  const mv = (e) => getX(e) - startPos.x;
  const mvY = (e) => getY(e) - startPos.y;
  const moving = startPos.x !== 0;

  const refsAreDefined = () => cardRef.current && knowRef.current && unknowRef.current && delRef.current;

  const applyMove = (e) => {
    if (!refsAreDefined()) return;
    cardRef.current!.style.left = `${mv(e) * 1.3}px`;
    cardRef.current!.style.top = `${mvY(e) * 1.3}px`;
    cardRef.current!.style.transform = `rotate(${mv(e) / -15}deg)`;
    const down = mvY(e) > 1 ? mvY(e) / MOVE / 1.4 : 0;
    knowRef.current!.style.opacity = `${mv(e) / MOVE - down}`;
    unknowRef.current!.style.opacity = `${mv(e) / -MOVE - down}`;
    delRef.current!.style.opacity = `${down - Math.abs(mv(e) / MOVE)}`;
  };
  const resetMove = (gestured?: Gesture) => {
    if (!refsAreDefined()) return;
    if (gestured === undefined) {
      knowRef.current!.style.removeProperty("opacity");
      unknowRef.current!.style.removeProperty("opacity");
      delRef.current!.style.removeProperty("opacity");
    }
    if (!gestured || !["known", "unknown"].includes(gestured)) cardRef.current!.style.removeProperty("left");
    if (!gestured || !["delete"].includes(gestured)) cardRef.current!.style.removeProperty("top");
    cardRef.current!.style.removeProperty("transform");
  };

  const handleClick = () => {
    if (p.index !== 0) return;

    if (!p.turned) {
      p.setTurned(true);
      !hintTapWasSeen && dispatch(nextTutorialTapPhase());
    } else {
      if (hintTapWasSeen && !contextSelectionDebounced.current) {
        setTimeout(() => applyMove({ pageX: 70, pageY: 1 }), 0);
        setTimeout(() => applyMove({ pageX: -70, pageY: 1 }), CARD_TRANS / 2);
        setTimeout(resetMove, CARD_TRANS);
        setLearnSwipesOpacity(0.7);
      }
    }
  };
  const handleStart = (e) => {
    if (isIos() && contextorSelection) return;
    setStartPos({ x: getX(e), y: getY(e) });
  };
  const handleMove = (e) => {
    if (startPos.x === 0 || contextorSelection) return;
    if (!p.turned && Math.abs(mv(e)) > 5) {
      p.setTurned(true);
      !hintTapWasSeen && dispatch(nextTutorialTapPhase());
      setStartPos({ x: 0, y: 0 });
    }
    if (p.turned) {
      applyMove(e);
      setLearnSwipesOpacity(0);
    }
  };
  const handleStop = () => {
    if (p.index !== 0 || startPos.x === 0) return;
    let gesture: Gesture | undefined = undefined;
    Number(knowRef.current!.style.opacity) >= 1 && (gesture = "know");
    Number(unknowRef.current!.style.opacity) >= 1 && (gesture = "unknow");
    Number(delRef.current!.style.opacity) >= 1 && (gesture = "delete");

    // making sure that in tutorial user can't do anything else than the required gesture
    if (
      !hintTapWasSeen &&
      ((tapPhase === TapPhases[1] && gesture !== "know") || (tapPhase === TapPhases[4] && gesture !== "unknow"))
    ) {
      gesture = undefined;
    }

    resetMove(gesture);
    setStartPos({ x: 0, y: 0 });

    if (gesture) {
      !hintTapWasSeen && dispatch(nextTutorialTapPhase());
      handleGesture(gesture);
    }
  };

  const handleGesture = (gesture: Gesture) => {
    if (isHintDialogOpen) return;
    p.onGesture(gesture);
    if (!refsAreDefined()) return;
    if (gesture === "unknow") {
      cardRef.current!.style.left = `-130%`;
      unknowRef.current!.style.opacity = "1";
      setTimeout(() => {
        if (!refsAreDefined() || p.isLast) return; // last card moved to left should disappear and don't return back
        cardRef.current!.style.removeProperty("left");
        unknowRef.current!.style.removeProperty("opacity");
      }, CARD_TRANS * 0.65);
    } else if (gesture === "delete") {
      cardRef.current!.style.top = `130%`;
      delRef.current!.style.opacity = "1";
      seeHint(CARDDELHINT);
    } else {
      cardRef.current!.style.left = `120%`;
      cardRef.current!.style.opacity = `0`;
      knowRef.current!.style.opacity = "1";
    }
  };

  const setKeyGesture = (keyGesture) => {
    if (p.index || !hintWasSeen(TUTORIALDONE) || isHintDialogOpen) return;
    if (!p.turned) return p.setTurned(true);
    keyGesture !== "other" ? setTimeout(() => handleGesture(keyGesture)) : handleClick();
  };
  useKeyMy("ArrowRight", () => setKeyGesture("know"), undefined, [p.turned, p.index]);
  useKeyMy("ArrowDown", () => setKeyGesture("delete"), undefined, [p.turned, p.index]);
  useKeyMy("ArrowLeft", () => setKeyGesture("unknow"), undefined, [p.turned, p.index]);
  useKeyMy(" ", () => setKeyGesture("other"), undefined, [p.turned, p.index]);
  useKeyMy("Enter", () => setKeyGesture("other"), undefined, [p.turned, p.index]);

  const front = <UpSize lang={p.lang}>{!deck?.back ? p.card.front : genderStyle(p.card.front, deck.front)}</UpSize>;

  // 50% change that the hint will appear on front side
  //  - on target-lang side, it will highlight the word (brown)
  //  - on native-lang side it will be hidden (___)
  const isHintInsteadFront = useMemo(
    () =>
      p.card.hint &&
      Math.random() < 0.5 &&
      p.card.hint.length < 90 &&
      includesHighlighter(p.card.hint, p.card.front, p.lang),
    [p.card.front, p.card.hint, p.lang]
  );

  const hint = (isInsideHintDialog: boolean) =>
    p.card.hint &&
    p.index === 0 &&
    deck?.front && (
      <>
        <HintTooltip
          name="CardLngPress"
          canOpen={(deck?.stats.known || 0) >= 8 && p.turned}
          title="If you don't understand a word from the example of usage, hold your finger on it."
        />
        <Hint
          $isHintOverflowing={overFlowing === "hint" && !isInsideHintDialog}
          ref={hintRef}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => overFlowing === "hint" && onExpandHintHandler(e)}
        >
          <UpSize lang={p.lang} ratio={0.5}>
            <Contextor
              hint={p.card.hint}
              onSelection={(s) => setContextorSelection(s)}
              onBlur={() => setContextorSelection(undefined)}
            >
              {parser(p.card.hint, {
                Wrapper: Example,
                before: <Speech small text={p.card.hint} lang={p.lang} />,
                highlighter: p.card.front,
                lang: p.lang
              })}
            </Contextor>
          </UpSize>
        </Hint>
      </>
    );

  const isBlurred =
    (p.card.knownCount === 2 || (!!loopFilter?.includeKnown && loopFilter?.loopMode === "listening")) &&
    !p.unBlurred &&
    !!tts &&
    !voiceOff;
  const blurClick = (e) => {
    if (!p.unBlurred && isBlurred) {
      e.stopPropagation();
      p.onUnblur();
    }
  };
  const onTranslateClick = (e) => {
    setTranslatationOpened(true);
    if (!!overFlowing) setIsHintDialogOpen(true);
    e.stopPropagation();
  };
  const onExpandHintHandler = (e) => {
    setTranslatationOpened(true);
    if (!isHintDialogOpen) setIsHintDialogOpen(true);
    e.stopPropagation();
  };

  return (
    <>
      <CardFlipWrap
        onClick={handleClick}
        onTouchStart={handleStart}
        onMouseDown={handleStart}
        onTouchMove={handleMove}
        onMouseMove={handleMove}
        onTouchEnd={handleStop}
        onMouseUp={handleStop}
        onMouseLeave={handleStop}
        ref={cardRef}
        moving={moving}
        index={p.index}
        putBelow={p.putBelow}
      >
        {p.index === 0 && viewer?.admin === 3 && !loopFilter?.includeKnown && <CardDebug card={p.card} />}
        <Flipper turned={p.turned} transition={p.index <= 0}>
          <CardFront>
            {p.index <= 1 && (
              <Cont blankForTurning={p.blankForTurning}>
                {!p.card.flipped ? (
                  <>
                    <Phrase>
                      <SpeechHintTooltip
                        canOpen={p.index === 0 && !!deck && deck.stats.known > 20 && canTts(p.lang)}
                        name={LONGPRESSHINT2}
                      >
                        <Speech text={p.card.front} lang={p.lang} />
                      </SpeechHintTooltip>

                      <Blurred blurred={isBlurred} onClick={blurClick}>
                        {front}
                        <Pronunciation lang={p.lang}>{p.card.pronunciation}</Pronunciation>
                      </Blurred>

                      {isBlurred && <SVisibilityIcon onClick={blurClick} />}
                    </Phrase>
                    {isHintInsteadFront && (
                      <UpSize lang={p.lang} style={{ opacity: 0.6 }}>
                        {parser(p.card.hint, {
                          Wrapper: Example,
                          highlighter: p.card.front,
                          lang: p.lang,
                          hide: isBlurred
                        })}
                      </UpSize>
                    )}
                  </>
                ) : (
                  <>
                    <FlatImg img={p.card.svg} />
                    <Phrase>{p.card.back}</Phrase>
                    {isHintInsteadFront && (
                      <UpSize lang={p.lang} style={{ opacity: 0.6 }}>
                        {parser(p.card.hint, {
                          Wrapper: Example,
                          highlighter: p.card.front,
                          lang: p.lang,
                          hide: true
                        })}
                      </UpSize>
                    )}
                  </>
                )}
              </Cont>
            )}

            {isBlurred && !p.unBlurred && !p.card.flipped && <SCantListenNow onClick={blurClick} />}
          </CardFront>
          {p.index <= 1 && (
            <CardBack>
              <Cont ref={contRef}>
                <FlatImg img={p.card.svg} />
                {!p.card.flipped ? (
                  <>
                    <Label>
                      <Speech text={p.card.front} small lang={p.lang} />
                      {front}
                      <Pronunciation lang={p.lang}>{p.card.pronunciation}</Pronunciation>
                    </Label>
                    <Phrase>{p.card.back}</Phrase>
                  </>
                ) : (
                  <>
                    <Label>{p.card.back}</Label>
                    <Phrase>
                      <Speech text={p.card.front} small lang={p.lang} />
                      {front}
                      <Pronunciation lang={p.lang}>{p.card.pronunciation}</Pronunciation>
                    </Phrase>
                  </>
                )}
                <Separator />
                {hint(false)}
                {!overFlowing && (
                  <Collapse in={translatationOpened && !translateIsLoading}>
                    <Translation>{translation}</Translation>
                  </Collapse>
                )}
                <Icons $isHintOverflowing={!!overFlowing}>
                  {grammar && <CardGrammarOpener grammar={grammar} canShowTooltip={p.turned && p.card.failCount > 5} />}
                  {p.card.hint && (!translateIsLoading || overFlowing) && (
                    <TranslateButton onClick={onTranslateClick}>
                      <FontAwesomeIcon icon={faTranslate} />
                    </TranslateButton>
                  )}
                  {translateIsLoading && !overFlowing && <TranslateLoader size={20} />}
                  {hintWasSeen(TUTORIALDONE) && p.card.sourceId && (
                    <CardSourceIcons fullOpacity card={p.card}></CardSourceIcons>
                  )}
                  <CardEditButton card={p.card} />
                </Icons>
              </Cont>
              <KnownSign ref={knowRef} />
              <UnknowSign ref={unknowRef} />
              <DelSign ref={delRef} />
            </CardBack>
          )}
        </Flipper>

        {p.turned && p.index === 0 && hintTapWasSeen && <SideClicks onGesture={handleGesture} />}
      </CardFlipWrap>
      {!hintWasSeen(CARDDELHINT) && p.index <= 1 && <CardHintDelete index={p.index}></CardHintDelete>}
      <DialogWindow open={isHintDialogOpen} onClose={() => setIsHintDialogOpen(false)}>
        <TranslationDialog>
          {hint(true)}
          <Translation>{translation}</Translation>
        </TranslationDialog>
      </DialogWindow>
    </>
  );
});

export default Card;
