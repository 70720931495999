import { Button, Radio, Checkbox, Dialog, DialogContent, DialogActions } from "@mui/material";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation, useQuery } from "relay-hooks";
import { CardSorterQuery } from "./__generated__/CardSorterQuery.graphql";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createSourceMutation } from "../../../queries/sources/__generated__/createSourceMutation.graphql";
import { createSourceQL } from "../../../queries/sources/createSource";
import Loader from "components/other/Loader";
import { CardSorterSourceQuery } from "./__generated__/CardSorterSourceQuery.graphql";
import { MAX_CARDS_IN_SINGLE_SET } from "../../../sharedJs__generated/constants";
import TransVariable from "../../../components/other/TransVariable";

const query = graphql`
  query CardSorterQuery($lang: String!, $langNative: String) {
    mine: public {
      sources(first: 1000, kind: set, lang: $lang, mine: true, langNative: $langNative)
        @connection(key: "Library_sources") {
        edges {
          node {
            id
            uri
            name(langNative: $langNative)
            counts {
              total
            }
          }
        }
      }
    }
  }
`;
export const sourceQL = graphql`
  query CardSorterSourceQuery($sourceId: ID!, $langNative: String) {
    node(id: $sourceId) {
      ... on Source {
        id
        name(langNative: $langNative)
        mine
      }
    }
  }
`;

const Li = styled.div<{ disabled?: boolean }>`
  text-align: left;
  display: flex;
  align-items: center;
  line-height: 1.4em;
  margin: 2px 0;
  cursor: pointer;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.palette.text.disabled};
    `}
`;
const GreenLi = styled(Li)`
  color: ${({ theme }) => theme.duo.color.green};
  margin: 3px 0 3px 2px;
  font-size: 90%;
  font-style: italic;
`;
const SaveLi = styled(Li)<{ disabled: boolean }>`
  font-size: 90%;
  margin: 7px 0 7px 7px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;
const SourceLi = styled(Li)`
  font-style: italic;
  opacity: 0.6;
  font-size: 95%;
  line-height: 1.4em;
  margin-left: 3px;
`;
const AddIcon = styled(AddCircleOutlineIcon)`
  color: ${({ theme }) => theme.duo.color.green};
`;
const SaveCheckbox = styled(Checkbox)`
  padding: 6px 3px 6px 0;
`;
const MaxHeight = styled.div`
  max-height: 50vh;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 0 10px #0003;
  padding: 8px 15px 8px 8px;
  margin: 0 -5px 8px;
`;
const SRadio = styled(Radio)`
  padding: 5px 8px 5px 2px;
`;

type Props = {
  lang: string;
  langNative?: string;
  sourceId?: string;
  values: { sourceId?: string | null; noDeck?: boolean };
  onDone: (variables: { sourceId: string | null; noDeck?: boolean }) => void;
  allowNoDeck?: boolean;
};

const CardSorter: React.FC<Props> = ({ onDone, lang, langNative, sourceId, values, allowNoDeck }) => {
  const [fetchKey, refetch] = React.useState(0);
  const { data, isLoading } = useQuery<CardSorterQuery>(
    query,
    { lang, langNative },
    { fetchPolicy: "store-and-network", fetchKey }
  );
  const { data: sourceData } = useQuery<CardSorterSourceQuery>(
    sourceQL,
    { sourceId: sourceId as string, langNative },
    { skip: !sourceId }
  );
  const source = sourceData?.node;
  const [createSource] = useMutation<createSourceMutation>(createSourceQL);
  const { t } = useTranslation();
  const [isFullDialogOpen, setIsFullDialogOpen] = useState(false);

  const [value, setValue] = React.useState<string | null>(values.sourceId || sourceId || null);
  const [noDeckValue, setNoDeckValue] = React.useState(values.noDeck === true);

  React.useEffect(() => {
    if (data?.mine.sources?.edges && fetchKey !== 0) {
      setValue(data.mine.sources.edges[0]?.node?.id || null);
    }
  }, [data, fetchKey]);

  React.useEffect(() => {
    if (!value || value === sourceId) {
      setNoDeckValue(false);
    }
  }, [value, sourceId]);

  const handleCreateSet = () => {
    const nameNative = prompt(t("Create a Set of cards"));
    if (nameNative) {
      createSource({
        variables: {
          nameNative,
          lang,
          langNative,
          kind: "set",
          difficulty: "moderate",
          private: true,
          duoLang: true
        },
        onCompleted: () => {
          refetch(Math.random());
        }
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Dialog open={isFullDialogOpen} onClose={() => setIsFullDialogOpen(false)}>
            <DialogContent>
              <Trans>
                You have reached the maximum of <TransVariable text={String(MAX_CARDS_IN_SINGLE_SET)} /> words for this
                set.
              </Trans>
            </DialogContent>
            <DialogActions sx={{ padding: 2, paddingTop: 0, alignItems: "center" }}>
              <Button color="primary" variant="contained" onClick={() => setIsFullDialogOpen(false)}>
                {t("ok")}
              </Button>
            </DialogActions>
          </Dialog>

          {source && !source.mine && (
            <SourceLi onClick={() => setValue(source.id || null)}>
              <SRadio checked={source.id === value} />
              {source.name}
            </SourceLi>
          )}
          <MaxHeight>
            {data?.mine.sources.edges?.map((src) => {
              const isFull = (src?.node?.counts?.total || 0) >= MAX_CARDS_IN_SINGLE_SET;
              return (
                <Li
                  key={src?.node?.id}
                  disabled={isFull}
                  onClick={(e) => {
                    if (isFull) {
                      setIsFullDialogOpen(true);
                    } else {
                      setValue(value === src?.node?.id ? (!source?.mine && source?.id) || null : src?.node?.id || null);
                    }
                  }}
                >
                  <SRadio disabled={isFull} checked={value === src?.node?.id} />
                  {src?.node?.name}
                </Li>
              );
            })}
            <GreenLi onClick={handleCreateSet}>
              <AddIcon /> &nbsp; <Trans>Create a Set of cards</Trans>
            </GreenLi>
          </MaxHeight>

          {allowNoDeck && (
            <SaveLi onClick={() => setNoDeckValue(!noDeckValue)} disabled={!value || value === sourceId}>
              <SaveCheckbox checked={!noDeckValue} /> &nbsp; <Trans>Save into my deck of cards</Trans>
            </SaveLi>
          )}

          <Button color="primary" variant="contained" onClick={() => onDone({ sourceId: value, noDeck: noDeckValue })}>
            <Trans>Done</Trans>
          </Button>
        </>
      )}
    </>
  );
};

export default CardSorter;
