import React from "react";
import styled from "styled-components/macro";
import { graphql } from "babel-plugin-relay/macro";
import { Form, Formik } from "formik";
import Button from "@mui/material/Button";
import { FTextField } from "../../styled/TextField";
import { useTranslation } from "react-i18next";
import { useMutation } from "relay-hooks";
import { useInviteHash } from "components/dialogs/InviteDialog/InviteDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { IconButton, Stack } from "@mui/material";
import { EmailAuthMutation } from "root/auth/__generated__/EmailAuthMutation.graphql";
import { EmailAuthForgottenMutation } from "root/auth/__generated__/EmailAuthForgottenMutation.graphql";
import { EmailAuthLoginMutation } from "root/auth/__generated__/EmailAuthLoginMutation.graphql";
import {
  EmailAuthGetEmailInfoMutation,
  EmailAuthGetEmailInfoMutation$data
} from "root/auth/__generated__/EmailAuthGetEmailInfoMutation.graphql";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Toggler, { TogglerAnimationLength } from "components/other/Toggler";
import OAuth from "components/other/OAuth";
import { snackbar, dispatchEvent, EVENT } from "tools/events";
import { useDelayedAutoFocus } from "hooks/useDelayedAutoFocus";
import { useSetState } from "components/ReduxProvider";
import { UserAction } from "components/initializers/UserActionsLogger";

export const getEmailInfoQL = graphql`
  mutation EmailAuthGetEmailInfoMutation($email: String!) {
    getEmailInfo(email: $email) {
      exists
      password
      google
      apple
    }
  }
`;
const loginQL = graphql`
  mutation EmailAuthLoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      deviceId
    }
  }
`;
const forgottenQL = graphql`
  mutation EmailAuthForgottenMutation($email: String!) {
    forgottenPassword(email: $email)
  }
`;
const registerQl = graphql`
  mutation EmailAuthMutation(
    $email: String!
    $password: String!
    $inviteHash: String
    $forceGuestToUserMerge: Boolean
  ) {
    register(
      email: $email
      password: $password
      inviteHash: $inviteHash
      forceGuestToUserMerge: $forceGuestToUserMerge
    ) {
      token
      deviceId
    }
  }
`;

const Forgotten = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  text-align: center;
  margin: -5px 0 10px 0;
`;

const Wrap = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 320px;
`;

const SBackButton = styled(Button)`
  margin-left: -22px;
  font-weight: normal;
`;
const ErrorText = styled.div`
  color: ${({ theme }) => theme.duo.color.red};
  padding: 0 0 10px 0;
`;

type EmailSteps = "Email" | "Registration" | "Login" | "Forgotten";
function getButtonText(step: EmailSteps): string {
  switch (step) {
    case "Login":
      return "Login";
    case "Registration":
      return "Sign Up";
    case "Email":
      return "Next";
    case "Forgotten":
      return "Update Password";
  }
}

type Props = {
  initialEmail?: string;
  registrationOnly?: boolean;
  onBackButton?: VoidFunction;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  forceGuestToUserMerge?: boolean;
};
const EmailAuth: React.FC<Props> = ({
  initialEmail,
  registrationOnly,
  onBackButton,
  onFocus,
  onBlur,
  forceGuestToUserMerge
}) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<EmailSteps>(registrationOnly ? "Registration" : "Email");
  const [email, setEmail] = React.useState<string>("");
  const [getEmailInfoMutation, { loading: checkingEmail }] = useMutation<EmailAuthGetEmailInfoMutation>(getEmailInfoQL);
  const [loginMutate, { loading: loginLoading }] = useMutation<EmailAuthLoginMutation>(loginQL);
  const [forgottenMutate, { loading: forgottenLoading }] = useMutation<EmailAuthForgottenMutation>(forgottenQL);
  const [registerMutate, { loading: registrationLoading }] = useMutation<EmailAuthMutation>(registerQl);
  const inviteHash: string | undefined = useInviteHash();
  const [errorText, setErrorText] = React.useState<string>("");
  const [preferredOAuth, setPreferredOAuth] = React.useState<"google" | "apple">();
  const passwordRef = React.useRef(null);
  const submitRef = React.useRef(null);
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
  const setToken = useSetState("token");

  // We need to wait until animation is finished before focusing on email input
  const autofocusRef = useDelayedAutoFocus(1000);

  const focusPassword = () => {
    (passwordRef as any).current?.focus();
    setTimeout(() => {
      (submitRef as any).current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, TogglerAnimationLength);
  };

  const handleSubmit = (formValues) => {
    if (step === "Email") {
      setEmail(formValues.email);
      getEmailInfoMutation({
        variables: { email: formValues.email },
        onCompleted: (response: EmailAuthGetEmailInfoMutation$data) => {
          const emailInfo = response.getEmailInfo;

          if (emailInfo.exists && emailInfo.password) {
            setStep("Login");
            focusPassword();
          }
          if (!emailInfo.exists) {
            setStep("Registration");
            focusPassword();
          }
          if (emailInfo.exists && !emailInfo.password) {
            setErrorText(`Please use Google or Apple login for this email.`);
            setPreferredOAuth(emailInfo.apple ? "apple" : "google");
          }
        }
      });
    }

    if (step === "Login") {
      loginMutate({
        variables: formValues,
        onCompleted: ({ login }) => {
          login && setToken(login.token);
          dispatchEvent(EVENT.logUserAction, { action: UserAction.userAuthorized, detail: { type: "email" } });
        }
      });
    }

    if (step === "Registration") {
      registerMutate({
        variables: { ...formValues, inviteHash, forceGuestToUserMerge },
        onCompleted: ({ register }) => {
          register && setToken(register.token);
          dispatchEvent(EVENT.logUserAction, { action: UserAction.userAuthorized, detail: { type: "email" } });
        }
      });
    }

    if (step === "Forgotten") {
      forgottenMutate({
        variables: { email: formValues.email },
        onCompleted: () => {
          snackbar(t("Check your email and update password using the link we sent you."));
          setStep("Login");
        }
      });
    }
  };

  const handleEmailChange = (e) => {
    if (email !== e.target.value) {
      setErrorText("");
    }

    if (!registrationOnly && (step === "Registration" || step === "Login") && email !== e.target.value) {
      setStep("Email");
    }
  };

  const handleForgotten = (e) => {
    setStep("Forgotten");
    setErrorText("");
    e.preventDefault();
  };

  return (
    <Wrap>
      <Formik enableReinitialize initialValues={{ email: initialEmail || "", password: "" }} onSubmit={handleSubmit}>
        <Form>
          <FTextField
            name="email"
            required
            label="Email"
            type="email"
            onKeyUp={handleEmailChange}
            inputProps={{
              tabIndex: 1
            }}
            inputRef={autofocusRef}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {errorText && (
            <>
              <ErrorText>{t(errorText)}</ErrorText>
              <OAuth hideApple={preferredOAuth === "google"} hideGoogle={preferredOAuth === "apple"} />
              <Forgotten>
                <a href="#forgotten" onClick={handleForgotten}>
                  {t("New Password")}
                </a>
                ?
              </Forgotten>
            </>
          )}

          <Toggler show={step === "Registration" || step === "Login"}>
            <FTextField
              name="password"
              label={t("Password")}
              type={passwordVisible ? "text" : "password"}
              InputProps={{
                inputProps: {
                  tabIndex: step === "Registration" || step === "Login" ? 2 : -1,
                  className: "rr-block rr-ignore"
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      edge="end"
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputRef={passwordRef}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {step === "Login" && (
              <Forgotten>
                <a href="#forgotten" onClick={handleForgotten}>
                  {t("forgotten password")}
                </a>
                ?
              </Forgotten>
            )}
          </Toggler>

          <Stack direction="row" justifyContent={registrationOnly ? "center" : "space-between"} alignItems="center">
            {!registrationOnly && <SBackButton onClick={onBackButton}>{t("Previous")}</SBackButton>}
            {!errorText && (
              <LoadingButton
                tabIndex={3}
                variant="contained"
                type="submit"
                loading={checkingEmail || loginLoading || registrationLoading || forgottenLoading}
                ref={submitRef}
              >
                {t(getButtonText(step))}
              </LoadingButton>
            )}
          </Stack>
        </Form>
      </Formik>
    </Wrap>
  );
};

export default EmailAuth;
