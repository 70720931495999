import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { setFlagMutation } from "queries/users/__generated__/setFlagMutation.graphql";
import React from "react";
import { useViewerQuery } from "queries/viewerQuery";

const mutation = graphql`
  mutation setFlagMutation($name: String!, $value: String, $remove: Boolean, $body: String) {
    setUserFlag(name: $name, value: $value, remove: $remove, body: $body) {
      flags {
        name
        value
        body
      }
    }
  }
`;

export const useSetFlag = () => {
  const { viewer } = useViewerQuery();
  const [mutate] = useMutation<setFlagMutation>(mutation);

  return React.useCallback(
    (variables: setFlagMutation["variables"]) =>
      mutate({
        variables,
        optimisticUpdater: (store) => {
          const viewerRec = viewer?.id && store.get(viewer.id);
          if (!viewerRec) return;
          const flags = viewerRec.getLinkedRecords("flags");
          if (!flags) return;
          let flg = flags?.find((f) => f?.getValue("name") === variables.name);
          if (!flg) {
            flg = store.create("newID" + Math.floor(Math.random() * 10e8), "UserFlags");
            viewerRec.setLinkedRecords([...flags, flg], "flags");
          }
          flg.setValue(variables.name, "name");
          flg.setValue(variables.value, "value");
          flg.setValue(variables.body, "body");
          flg.setValue(Date.now(), "updated");
        }
      }),
    [mutate, viewer?.id]
  );
};
