import React from "react";
import { Trans } from "react-i18next";
import { Button, styled } from "@mui/material";
import { inAppBrowser } from "../../../tools/device";
import { setQuestAddSource } from "components/ReduxProvider";
import { useDispatch } from "react-redux";

type Props = {};

const SButton = styled(Button)`
  margin: 10px 0;
`;

const ChromeHint: React.FC<Props> = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ height: 30 }} />
      <img src="https://cdn.duocards.com/img/laptopChrome.png" width="220" height="203.5" alt="laptop" />
      <p>
        <Trans parent="strong">Chrome Extension for saving words from your laptop!</Trans>
      </p>
      <Trans parent={"p"}>You can install it from here.</Trans>
      <SButton
        variant={"contained"}
        onClick={() => {
          const chromeLink = "https://chrome.google.com/webstore/detail/duocards/bgojhcbkjlloemicdchpifpnbcjiijmd";
          if (inAppBrowser()) {
            // NOTE: user probably won't see the whole animation for finishing quest, but it would be complicated to manage that
            setTimeout(() => dispatch(setQuestAddSource("addChrome")), 500);
            inAppBrowser().open(chromeLink, "_system");
          } else {
            dispatch(setQuestAddSource("addChrome"));
            window.location.href = chromeLink;
          }
        }}
      >
        <Trans>Install extension now</Trans>
      </SButton>
      <div>
        <Button sx={{ color: "#777" }} onClick={() => dispatch(setQuestAddSource("addChrome"))}>
          <Trans>Maybe later</Trans>
        </Button>
      </div>
    </>
  );
};

export default ChromeHint;
