import { parseISO } from "date-fns";
import React from "react";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import { XpsFreezeEnum } from "sharedJs__generated/constants";
import styled from "styled-components/macro";
import { Mutable } from "utility-types";
import { strengthFn } from "./FireStats";
import Flames, { CubeImg } from "./Flames";

const Wrap2 = styled.div`
  position: relative;
`;
const Wrap = styled.div`
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  overflow: auto;
  padding: 10px 0 22px;
  display: flex;
`;
const Cont = styled.div`
  padding: 0 30px 0 110px;
  width: fit-content;
`;
const Flex = styled.div`
  display: flex;
`;
const Days = styled(Flex)`
  font-size: 11px;
  line-height: 2em;
  font-weight: bold;
  opacity: 0.7;
`;
const Day = styled.div`
  width: 56px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Numbers = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.duo.color.textGrey};
  line-height: 1.7em;
`;
const Added = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
`;
const StreakFlex = styled(Flex)`
  position: relative;
  padding: 6px 0;
`;
const Streak = styled.div`
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 11px);
  top: 3px;
  left: 2px;
  background: ${({ theme }) => theme.duo.color.gold}33;
  border: 1px solid ${({ theme }) => theme.duo.color.gold}88;
  border-radius: 100px;
`;
const SCubeImg = styled(CubeImg)`
  width: 30px;
  top: 2px;
  left: 12px;
`;
const Legend = styled(Numbers)`
  position: absolute;
  bottom: 18px;
  left: -24px;
  text-align: right;
  padding: 4px 10px 4px 14px;
  border-radius: 8px;
  background: white;
  border: 1px solid ${({ theme }) => theme.duo.color.grey}aa;
  pointer-events: none;
`;

const SDayWrap = styled.div`
  position: relative;
`;

type Props = {
  days: readonly {
    when: string;
    xp: number | null;
    streaked: boolean;
    freeze: string | null;
    reviewed: number | null;
    added: number | null;
  }[];
};

const FireStatsGraph: React.FC<Props> = ({ days }) => {
  const lastDayRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  useEffectOnce(() => {
    lastDayRef.current && lastDayRef.current.scrollIntoView({ block: "center", inline: "center" });
  });

  let streaks: Mutable<Props["days"]>[] = [];
  days.forEach((day, index) => {
    if (day.streaked && index && days[index - 1].streaked && streaks.length !== 0) {
      streaks[streaks.length - 1].push(day);
    } else {
      streaks.push([day]);
    }
  });

  return (
    <Wrap2>
      <Wrap>
        <Cont>
          <Days>
            {days.map((day, i) => (
              <Day key={i} ref={i === days.length - 1 ? lastDayRef : undefined}>
                {i === days.length - 1 ? (
                  <Trans>Today</Trans>
                ) : (
                  parseISO(day.when)
                    .toLocaleDateString(undefined, { day: "numeric", month: "numeric" })
                    .replace(" ", "")
                )}
              </Day>
            ))}
          </Days>
          <Flex>
            {streaks.map((streak, i) => (
              <StreakFlex key={i}>
                {streak[0].streaked && <Streak />}
                {streak.map((day, j) => {
                  return (
                    <SDayWrap key={day.when}>
                      <Day>
                        <Flames xps={day.xp} noZZ strength={!day.streaked ? 0 : strengthFn((day.xp || 0) - 4)} />
                      </Day>
                      {day && (day.freeze === XpsFreezeEnum.frozen || day.freeze === XpsFreezeEnum.frozenNotified) && (
                        <SCubeImg coldness={3} className="nofilter" />
                      )}
                    </SDayWrap>
                  );
                })}
              </StreakFlex>
            ))}
          </Flex>
          <Numbers>
            <Added>
              <Flex>
                {days.map((day, i) => (
                  <Day key={i}>{day.added || "-"}</Day>
                ))}
              </Flex>
            </Added>
            <Flex>
              {days.map((day, i) => (
                <Day key={i}>{day.reviewed || "-"}</Day>
              ))}
            </Flex>
          </Numbers>
        </Cont>
      </Wrap>
      <Legend>
        <Added>
          <Trans>Added</Trans>:
        </Added>
        <div>
          <Trans>Checked</Trans>:
        </div>
      </Legend>
    </Wrap2>
  );
};

export default FireStatsGraph;
