import styled from "styled-components";
import { devicePaddings } from "tools/device";
import PriceButton from "./PriceButton";
import { StoreId, useStoreIds } from "./subscriptionIds";
import { useSubscriptions } from "./subscriptionHooks";
import { useViewerQuery } from "queries/viewerQuery";

const { bottom } = devicePaddings();

const SubButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 10; // above manage and restore buttons
`;
const ButtonIn = styled.div`
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  margin-inline: auto;
  padding: 3px 30px ${bottom + 8}px;
  background: white;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
`;

type Props = {
  plan: StoreId;
};
const SubscriptionBottom = ({ plan }: Props) => {
  const { storeIdYear, storeIdMonth } = useStoreIds();
  const { subscriptions } = useSubscriptions();
  const {
    subscriptionFlags: { hasActiveMonthlySub, hasActiveYearlySub, isSubscriptionCancelled, isLemonSubscription }
  } = useViewerQuery();

  //  year@p1y and year@p1y@trial are the same plans, check for occurence of either
  const isYearlyActive = (plan.includes(storeIdYear) || storeIdYear.includes(plan)) && hasActiveYearlySub;
  const isMonthlyActive = (plan.includes(storeIdMonth) || storeIdMonth.includes(plan)) && hasActiveMonthlySub;

  const SubButton = () => {
    if (isYearlyActive || isMonthlyActive) {
      return <PriceButton bought fullWidth />;
    } else if ((subscriptions && !subscriptions[plan]?.canPurchase) || (plan === storeIdMonth && hasActiveYearlySub)) {
      return <PriceButton notAvailable fullWidth />;
    } else if (!subscriptions) {
      return <PriceButton notAvailable fullWidth />;
    } else if (isLemonSubscription && !isSubscriptionCancelled) {
      return <PriceButton notAvailable fullWidth />;
    } else {
      return <PriceButton storeId={plan} fullWidth />;
    }
  };

  return (
    <SubButtonWrapper>
      <ButtonIn>
        <SubButton />
      </ButtonIn>
    </SubButtonWrapper>
  );
};

export default SubscriptionBottom;
