import React from "react";
import { AdminUserType } from "./AdminUser";
import { Button, TextField } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useNavigate } from "react-router";
import { AdminUserActionsLoginAsMutation } from "root/admin/adminUsers/__generated__/AdminUserActionsLoginAsMutation.graphql";
import styled from "styled-components/macro";
import { AdminUserActionsActivateAffiliateMutation } from "root/admin/adminUsers/__generated__/AdminUserActionsActivateAffiliateMutation.graphql";
import DialogWindow from "components/other/DialogWindow";
import SubscribeManually from "./SubscribeManually";
import { PROFILE } from "../../RootRouter.config";
import { useLogout } from "../../../queries/users/logout";

const loginQL = graphql`
  mutation AdminUserActionsLoginAsMutation($email: String!) {
    loginAs(email: $email) {
      token
      deviceId
    }
  }
`;

const activateAffiliateQL = graphql`
  mutation AdminUserActionsActivateAffiliateMutation($userId: String!, $affiliateName: String!) {
    activateAffiliate(userId: $userId, affiliateName: $affiliateName) {
      id
      affiliate
    }
  }
`;

// Radim: toto bych čéče ještě nemazal, však do budoucna admin určitě bude moct něco nastavovat, tak ať ten dotaz nemusíme psát znova
// const setFlagQL = graphql`
//   mutation AdminUserActionsSetFlagMutation($name: String!, $value: String, $remove: Boolean, $userId: String!) {
//     adminSetUserFlag(name: $name, value: $value, remove: $remove, userId: $userId) {
//       flags {
//         name
//         value
//       }
//     }
//   }
// `;

type Props = {
  user: AdminUserType;
};

const Option = styled.div`
  &:not(:empty) {
    margin-top: 15px;
  }
`;

export const AdminUserActions: React.FC<Props> = ({ user }) => {
  const [loginAsMutate] = useMutation<AdminUserActionsLoginAsMutation>(loginQL);
  const [activateAffiliateMutate] = useMutation<AdminUserActionsActivateAffiliateMutation>(activateAffiliateQL);
  const navigate = useNavigate();
  const [affiliateOpen, setAffiliateOpen] = React.useState(false);
  const [affiliateName, setAffiliateName] = React.useState("");
  const setAdminOrigin = useSetState("adminOrigin");
  const setToken = useSetState("token");
  const token = useGetState("token");
  const logout = useLogout();

  const handleLoginAs = () => {
    if (!user.email) return;

    loginAsMutate({
      variables: { email: user.email },
      onCompleted: async ({ loginAs }) => {
        if (loginAs) {
          await logout();
          setAdminOrigin(token);
          setToken(loginAs.token);
        }
        navigate(PROFILE.url());
      }
    });
  };
  const handleActivateAffiliate = () => {
    setAffiliateOpen(false);
    activateAffiliateMutate({
      variables: { userId: user.id, affiliateName }
    });
  };

  return (
    <>
      <Option>
        {!user.affiliate && (
          <Button variant="outlined" size="small" onClick={() => setAffiliateOpen(true)}>
            Activate affiliate program
          </Button>
        )}
      </Option>

      <Option>
        <Button variant="outlined" size="small" onClick={handleLoginAs} disabled={!user.email}>
          Login as this user
        </Button>
      </Option>

      <Option>
        <SubscribeManually user={user} />
      </Option>

      <DialogWindow
        open={affiliateOpen}
        onClose={() => {
          setAffiliateOpen(false);
        }}
        title={"Affiliate name"}
      >
        <TextField
          value={affiliateName}
          onChange={(e) => setAffiliateName(e.currentTarget.value)}
          autoFocus
          placeholder="Affiliate name to be used in the referrer link"
        />
        <Button variant="contained" onClick={() => handleActivateAffiliate()}>
          Set
        </Button>
      </DialogWindow>
    </>
  );
};
