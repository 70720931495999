import { useStoreIds } from "./subscriptionIds";
import styled from "styled-components";
import { dividePrice } from "tools/price";
import { StoreId } from "./subscriptionIds";
import { useSaleExpiration, useSubscriptions } from "./subscriptionHooks";

const Crossed = styled.del`
  font-size: 11.5px;
  line-height: 1em;
  margin: 8px 0 0;
`;

type Props = {
  plan: StoreId;
  className?: string;
};

const PriceExtra = ({ plan, className }: Props) => {
  const { saleDiscount } = useSaleExpiration();
  const { storeIdYear, storeIdFamily } = useStoreIds();
  const { subscriptions } = useSubscriptions();

  if (!subscriptions) return null;

  /**
   * we are using dividePrice for multiplying
   * - 0.4 if sale is 60% => 800/0.4 = 2000
   * - 0.6 if tsale is 40% => 1200/0.6 = 2000
   * - and it's multiplied by 0.66 for family => 2000/0.66 = 3330
   * see price.spec.ts
   */
  const yearDivider = (100 - saleDiscount) / 100;

  let extraPrice: string | number | null | undefined = null;
  if (plan === storeIdYear) {
    extraPrice = dividePrice(subscriptions[storeIdYear], yearDivider);
  } else if (plan === storeIdFamily) {
    extraPrice = dividePrice(subscriptions[storeIdYear], yearDivider * 0.6);
  }

  return <Crossed className={className}>{extraPrice}</Crossed>;
};

export default PriceExtra;
