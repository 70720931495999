import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useTtsQuery } from "../../queries/ttsQuery";
import { silenceTts } from "../../queries/tts/ttsTools";
import { useDeviceLang } from "../../hooks/deviceHooks";
import MammothIcon from "../../icons/MammothIcon";
import Flames from "../fire/Flames";
import FlameBg from "../../icons/garden/flame/FlameBg";
import { useEffectOnce } from "react-use";
import { useNavigate } from "react-router";
import { devicePaddings } from "tools/device";
import { useTimedActions } from "hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  box-shadow: inset 0 -20px 100px #ffffff85;
  padding: ${top}px 0 ${bottom}px 0;
  .showing {
    opacity: 1;
    transition: opacity 1s;
  }
  .hidden {
    opacity: 0;
    visibility: hidden;
  }
`;
const In = styled.div`
  margin: 0;
  padding: 0;
  height: 100dvh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  p {
    position: absolute;
    top: 30px;
    width: 100%;
    text-align: center;
    margin: 8px 0;
  }
`;
const SButtonWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  z-index: 4;
`;
const SSkipButton = styled(Button)`
  align-self: center;
  padding: 11px 22px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 140px;
  left: 0;
  z-index: 4;
`;

const Hill = styled.div`
  position: absolute;
  top: 200px;
  left: -150%;
  z-index: 1;
  width: 400%;
  height: 200%;
  border-radius: 50%;
  background: #efd url("https://cdn.duocards.com/img/grass3.png");
`;

const slideInFromLeft = keyframes`
  0% {transform: translateX(-100%) scaleX(1); }
  100% { transform: translateX(0%) scaleX(1); }
  101% { transform: translateX(0) scaleX(-1); }
`;

const slideInFromRight = keyframes`
  0% {transform: translateX(+100%) scaleX(1); }
  100% { transform: translateX(0%) scaleX(1); }
  101% { transform: translateX(0) scaleX(-1); }
`;

const FirstMammothWrap = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 238px;
  left: 5%;
  z-index: 3;
  animation: 4s ease-out 0s 1 ${slideInFromLeft};
`;
const FirstMammothIcon = styled(MammothIcon)`
  width: 50px;
  height: 50px;
`;
const FirstFlameWrap = styled.div`
  position: absolute;
  top: 250px;
  left: 20%;
  width: 50px;
  height: 50px;
  z-index: 2;
`;
const FirstFlames = styled(Flames)``;

const SecondMammothIcon = styled(MammothIcon)`
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 43%;
  right: 15%;
  z-index: 3;
  transform: scaleX(1);
  animation: 4s ease-out 0s 1 ${slideInFromRight};
`;
const SecondFlameWrap = styled.div`
  position: absolute;
  bottom: 40%;
  left: 35%;
  width: 100px;
  height: 100px;
  z-index: 2;
`;

const SecondFlames = styled(Flames)`
  transform: scale(2);
  position: absolute;
  top: 8px;
  left: 8px;
`;

const SecondFlameBg = styled(FlameBg)`
  width: 60px;
  height: 60px;
`;

const ThirdMammothWrap = styled.div`
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: 30px;
  left: -20%;
  z-index: 3;
  animation: 4s ease-out 0s 1 ${slideInFromLeft};
`;
const ThirdMammothIcon = styled(MammothIcon)`
  width: 300px;
  height: 300px;
`;
const ThirdFlameWrap = styled.div`
  position: absolute;
  bottom: 30px;
  right: -10%;
  width: 170px;
  height: 170px;
  z-index: 3;
`;
const ThirdFlames = styled(Flames)`
  transform: scale(4);
  position: absolute;
  top: 37px;
  left: 37px;
`;
const ThirdFlameBg = styled(FlameBg)`
  width: 140px;
  height: 130px;
`;

type Props = { onClose: VoidFunction };

const InternAdFlames: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { ttsLang } = useDeviceLang();
  const navigate = useNavigate();
  const tts = useTtsQuery(ttsLang);
  const [mammothCounter, setMammothCounter] = React.useState(0);
  const [firstMovingMammoth, setFirstMovingMammoth] = React.useState(true);
  const [secondMovingMammoth, setSecondMovingMammoth] = React.useState(true);
  const [thirdMovingMammoth, setThirdMovingMammoth] = React.useState(true);
  const [visibleIndex, setVisibleIndex] = React.useState(0);

  useTimedActions([
    // mammoth states
    { delay: 500, callback: () => setMammothCounter(1) },
    { delay: 4500, callback: () => setFirstMovingMammoth(false) },
    { delay: 3000, callback: () => setMammothCounter(2) },
    { delay: 7000, callback: () => setSecondMovingMammoth(false) },
    { delay: 4500, callback: () => setMammothCounter(3) },
    { delay: 8500, callback: () => setThirdMovingMammoth(false) },

    // displaying elements
    { delay: 0, callback: () => setVisibleIndex(1) },
    { delay: 1500, callback: () => setVisibleIndex(2) },
    { delay: 3000, callback: () => setVisibleIndex(3) },
    { delay: 4500, callback: () => setVisibleIndex(4) },
    { delay: 6000, callback: () => setVisibleIndex(5) },
    { delay: 7500, callback: () => setVisibleIndex(6) }
  ]);

  useEffectOnce(() => {
    tts &&
      tts({
        text: t("Don't be limited by your flames. You can do more and go farther with a subscription."),
        speakingRate: 1
      });
  });

  const order = (e) => {
    e.stopPropagation();
    onClose();
    navigate(SUBSCRIPTION.url());
  };

  return (
    <Wrap>
      <In>
        <Hill />
        <Trans parent="p" className={visibleIndex >= 1 ? "showing" : "hidden"}>
          Don't be limited by your flames.
        </Trans>
        <SButtonWrap className={visibleIndex >= 2 ? "showing" : "hidden"}>
          <Button variant="contained" color="primary" size="large" onClick={order}>
            <Trans>Subscribe</Trans>
          </Button>
        </SButtonWrap>
        {mammothCounter >= 1 && (
          <FirstMammothWrap>
            <FirstMammothIcon walking={firstMovingMammoth} />
          </FirstMammothWrap>
        )}
        <FirstFlameWrap>
          <FirstFlames strength={-1} xps={0} className={visibleIndex >= 3 ? "showing" : "hidden"} infinite={true} />
          <FlameBg />
        </FirstFlameWrap>
        {mammothCounter >= 2 && <SecondMammothIcon walking={secondMovingMammoth} />}
        <SecondFlameWrap>
          <SecondFlames strength={1} xps={30} className={visibleIndex >= 4 ? "showing" : "hidden"} infinite={true} />
          <SecondFlameBg />
        </SecondFlameWrap>
        {mammothCounter >= 3 && (
          <ThirdMammothWrap>
            <ThirdMammothIcon walking={thirdMovingMammoth} crown={true} />
          </ThirdMammothWrap>
        )}
        <ThirdFlameWrap>
          <ThirdFlames strength={5} xps={99} className={visibleIndex >= 5 ? "showing" : "hidden"} infinite={true} />
          <ThirdFlameBg />
        </ThirdFlameWrap>
        <SSkipButton
          className={visibleIndex >= 6 ? "showing" : "hidden"}
          variant="text"
          size="medium"
          onClick={() => {
            silenceTts();
            onClose();
          }}
        >
          <Trans>I'm fine with the limit.</Trans>
        </SSkipButton>
      </In>
    </Wrap>
  );
};

export default InternAdFlames;
