import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import DoneIcon from "@mui/icons-material/Done";
import { useSaleExpiration, useSubscribe, useSubscriptions } from "./subscriptionHooks";
import { StoreId, useStoreIds } from "./subscriptionIds";
import { useGetState } from "components/ReduxProvider";
import Snowfall from "react-snowfall";
import { SALE } from "sharedJs__generated/constants";

const SButton = styled(Button)<{ fullWidth?: boolean; $green?: boolean }>`
  margin: 20px auto;
  padding: 10px 40px;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  white-space: nowrap;
  ${({ $green }) => $green && `background: green !important;`}
`;

type Props = {
  storeId?: StoreId;
  bought?: boolean;
  notAvailable?: boolean;
  notInBrowser?: boolean;
  fullWidth?: boolean;
};

const PriceButton: React.FC<Props> = ({ storeId: storeIdParam, bought, notAvailable, notInBrowser, fullWidth }) => {
  const { subscriptions } = useSubscriptions();
  const { storeIdYear } = useStoreIds();
  const { saleId } = useSaleExpiration();
  const orderOpened = useGetState("orderOpened");
  const { t } = useTranslation();

  const storeId = storeIdParam || storeIdYear;
  const subscription = subscriptions?.[storeId];

  const title = subscription?.isTrial ? "Start free trial" : "Subscribe";
  const christmasFamilySale = saleId === SALE.christmasFamily && storeId.includes("family");

  const subscribe = useSubscribe();

  if (bought || notAvailable) {
    return (
      <SButton
        variant="contained"
        style={{ pointerEvents: "none" }}
        disabled={notAvailable || notInBrowser || bought}
        fullWidth={fullWidth}
      >
        {notInBrowser && <Trans>Subscribe in mobile App</Trans>}
        {notAvailable && <>N / A</>}
        {bought && <DoneIcon />}
      </SButton>
    );
  }

  if (!storeId || !subscriptions) return null;

  const handleClick = () => {
    if (saleId === SALE.christmasFamily && storeId === storeIdYear && !(window as any).christmasWarnedAlready) {
      (window as any).christmasWarnedAlready = true; // warn only once per session
      alert(t("Hey, consider buying a family subscription for the same price!"));
      return;
    }
    subscribe(storeId);
  };

  return (
    <SButton variant="contained" $green={christmasFamilySale} onClick={handleClick} fullWidth={fullWidth}>
      {orderOpened ? <CircularProgress style={{ color: "white" }} size={24} /> : <Trans>{title}</Trans>}
      {christmasFamilySale && <Snowfall snowflakeCount={5} />}
    </SButton>
  );
};

export default PriceButton;
