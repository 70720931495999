/**
 * @generated SignedSource<<00bbbf190bc025a8050d47aec3f33477>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OAuthProviderId = "apple" | "google" | "%future added value";
export type OAuthMutation$variables = {
  clientId?: string | null;
  forceGuestToUserMerge?: boolean | null;
  idToken: string;
  idTokenFirebase?: string | null;
  inviteHash?: string | null;
  oauthProviderId?: OAuthProviderId | null;
  photoUrl?: string | null;
};
export type OAuthMutation$data = {
  readonly oauth: {
    readonly deviceId: string | null;
    readonly token: string;
  } | null;
};
export type OAuthMutation = {
  response: OAuthMutation$data;
  variables: OAuthMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forceGuestToUserMerge"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idToken"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "idTokenFirebase"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "inviteHash"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "oauthProviderId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "photoUrl"
},
v7 = [
  {
    "kind": "Variable",
    "name": "clientId",
    "variableName": "clientId"
  },
  {
    "kind": "Variable",
    "name": "forceGuestToUserMerge",
    "variableName": "forceGuestToUserMerge"
  },
  {
    "kind": "Variable",
    "name": "idToken",
    "variableName": "idToken"
  },
  {
    "kind": "Variable",
    "name": "idTokenFirebase",
    "variableName": "idTokenFirebase"
  },
  {
    "kind": "Variable",
    "name": "inviteHash",
    "variableName": "inviteHash"
  },
  {
    "kind": "Variable",
    "name": "oauthProviderId",
    "variableName": "oauthProviderId"
  },
  {
    "kind": "Variable",
    "name": "photoUrl",
    "variableName": "photoUrl"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "oauth",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OAuthMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "oauth",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5626a1f27a206395e5934d9f42dc3d0",
    "id": null,
    "metadata": {},
    "name": "OAuthMutation",
    "operationKind": "mutation",
    "text": "mutation OAuthMutation(\n  $idToken: String!\n  $idTokenFirebase: String\n  $photoUrl: String\n  $inviteHash: String\n  $oauthProviderId: OAuthProviderId\n  $clientId: String\n  $forceGuestToUserMerge: Boolean\n) {\n  oauth(idToken: $idToken, idTokenFirebase: $idTokenFirebase, photoUrl: $photoUrl, inviteHash: $inviteHash, oauthProviderId: $oauthProviderId, clientId: $clientId, forceGuestToUserMerge: $forceGuestToUserMerge) {\n    token\n    deviceId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc4bc1fddd8dc57fd6a5c59ed3e0c2a0";

export default node;
