import React from "react";
import { Button, iconButtonClasses, paperClasses } from "@mui/material";
import DialogWindow from "components/other/DialogWindow";
import { Trans } from "react-i18next";
import { useViewerQuery } from "queries/viewerQuery";
import { useMatch, useNavigate } from "react-router";
import { SUBSCRIPTION } from "../../../root/profile/ProfileRouter.config";
import styled from "styled-components/macro";
import { TUTORIALDONE } from "../../../root/tutorial/steps/Verdict";
import { SALE } from "sharedJs__generated/constants";
import { useSaleExpiration } from "root/profile/subscription/subscriptionHooks";
import SaleMainBlackFriday from "./SaleMainBlackFriday";
import SaleMainChristmas from "./SaleMainChristmas";
import { useGetState, useSetState } from "components/ReduxProvider";

const forSales = [SALE.blackFriday, SALE.christmasFamily];

const SDialogWindow = styled(DialogWindow)<{ $christmas?: boolean }>`
  .${paperClasses.root} {
    background: ${({ $christmas }) => ($christmas ? "linear-gradient(135deg, #0f0c29, #1b5e20, #ff8f00)" : "black")};
    border: none;
  }
  .${iconButtonClasses.root} {
    color: white;
  }
`;
const Hero = styled.div`
  color: white;
  text-align: center;
  margin: 20px 0;
`;
const SButton = styled(Button)`
  margin-bottom: 40px;
`;

const BlackFridayDialog = () => {
  const { viewer, subscriptionFlags, hintWasSeen } = useViewerQuery();
  const [open, setOpen] = React.useState(false);
  const lastSeen = useGetState("saleMainDialogLastSeen");
  const setLastSeen = useSetState("saleMainDialogLastSeen");
  const navigate = useNavigate();
  const { saleId } = useSaleExpiration();
  const subscriptionUrl = useMatch(SUBSCRIPTION.url());

  React.useEffect(() => {
    if (
      saleId &&
      lastSeen !== saleId &&
      forSales.includes(saleId) &&
      !subscriptionFlags.hasActiveYearlySub &&
      !subscriptionFlags.hasActiveFamilySub &&
      hintWasSeen(TUTORIALDONE) &&
      viewer &&
      viewer.createdBeforeDays >= 1
    ) {
      setOpen(true);
    }
  }, [lastSeen, saleId, subscriptionFlags, hintWasSeen, viewer]);

  React.useEffect(() => {
    subscriptionUrl && handleClose(); // if user comes from notification directly on subscription page, close the dialog
  });

  function handleClose() {
    setOpen(false);
    setLastSeen(saleId);
  }

  function handleSubscribeClick() {
    handleClose();
    navigate(SUBSCRIPTION.url());
  }

  return (
    <SDialogWindow open={open} onClose={handleClose} $christmas={saleId === SALE.christmasFamily}>
      <Hero>{saleId === SALE.blackFriday ? <SaleMainBlackFriday /> : <SaleMainChristmas />}</Hero>

      <SButton variant="contained" color="primary" size="large" onClick={handleSubscribeClick}>
        <Trans>See options</Trans>
      </SButton>
    </SDialogWindow>
  );
};

export default BlackFridayDialog;
