import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { devicePaddings } from "../../tools/device";
import { useSaleExpiration, useSubscribe, useSubscriptions } from "../../root/profile/subscription/subscriptionHooks";
import { FIRSTSUBSCRIPTION } from "../../root/tutorial/misc/FirstSubscription";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import SubscriptionPromocode from "../../root/profile/subscription/SubscriptionPromocode";
import { useTimedActions } from "../../hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { SALE } from "sharedJs__generated/constants";
import InternalAdOverviewBenefits from "./InternalAdOverviewBenefits";
import InternalAdOverviewChristmas from "./InternalAdOverviewChristmas";
import PriceExtra from "root/profile/subscription/PriceExtra";
import { useStoreIds } from "root/profile/subscription/subscriptionIds";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import InternalAdFirstPaywallSale from "./InternalAdFirstPaywallSale";
import TransVariable from "components/other/TransVariable";

const { top, bottom } = devicePaddings();

const Wrap = styled.div<{ $blackFriday: boolean; $christmasFamily: boolean }>`
  height: 100%;
  padding: ${top + 55}px 0 ${bottom + 30}px;
  color: white;
  text-align: center;
  background: ${(p) =>
    p.$blackFriday
      ? "black"
      : p.$christmasFamily
        ? "linear-gradient(135deg, #0f0c29, #1b5e20, #ff8f00)"
        : p.theme.duo.color.themeGradient};
  .hidden {
    opacity: 0;
    visibility: hidden;
  }
  .showing {
    opacity: 1;
    transition: opacity 1s;
  }
  .showing.op-80 {
    opacity: 0.8;
  }
`;
const In = styled.div`
  display: flex;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
export const Top = styled.div`
  font-size: 30px;
  font-family: "Baloo Chettan 2", Arial, sans-serif;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  text-align: center;
  line-height: 1.3em;
`;
const SButton = styled(Button)`
  &&& {
    background: white;
    color: ${({ theme }) => theme.duo.color.primary};
    margin: 18px 0;
    white-space: nowrap;
  }
`;
const Price = styled.div`
  font-weight: bold;
`;
const FreeAndPrice = styled.div`
  font-size: 90%;
`;
const CloseButton = styled.div`
  padding: 3px;
  border-radius: 30px;
  font-size: 80%;
  position: absolute;
  top: ${top + 10}px;
  right: 10px;
  opacity: 0;
  transition: all 1s;
  color: white;
  display: flex;
  svg {
    margin-left: 5px;
  }
`;
const SSubscriptionPromocode = styled(SubscriptionPromocode)`
  color: rgb(221, 221, 221);
  display: block;
`;
const Title = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 10px 0 0;
`;
const SPriceExtra = styled(PriceExtra)`
  display: block;
  margin: 12px 0;
  font-size: 13px;
`;

type Props = { onClose: VoidFunction };

const InternAdOverview: React.FC<Props> = ({ onClose }) => {
  const { subscriptions } = useSubscriptions();
  const { storeIdYear, storeIdFamily } = useStoreIds();
  const { hintWasSeen, viewer, isSubscriber, getFlag } = useViewerQuery();
  const subscribe = useSubscribe();
  const navigate = useNavigate();
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  const { t } = useTranslation();

  const { saleId } = useSaleExpiration();
  const isBlackFriday = saleId === SALE.blackFriday;
  const isChristmasFamily = saleId === SALE.christmasFamily;

  useTimedActions([
    { delay: 500, callback: () => setVisibleIndex(1) },
    { delay: 1500, callback: () => setVisibleIndex(2) },
    { delay: 2500, callback: () => setVisibleIndex(3) },
    { delay: 2500, callback: () => setVisibleIndex(4) }
  ]);

  const order = (e) => {
    if (subscriptions) {
      if (!hintWasSeen(FIRSTSUBSCRIPTION)) {
        subscribe();
      } else {
        navigate(SUBSCRIPTION.url());
      }
    } else {
      onClose();
    }
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (isSubscriber) {
      onClose();
    }
  }, [isSubscriber, onClose]);

  const saleOnFirstPaywall = !hintWasSeen(TUTORIALDONE) && getFlag("abtest_saleOnFirstPaywall")?.value === "on";

  return (
    <Wrap $blackFriday={isBlackFriday} $christmasFamily={isChristmasFamily}>
      <CloseButton onClick={onClose} className={visibleIndex >= 4 ? "showing op-80" : "hidden"}>
        <Trans>Skip for now</Trans>
        <CloseIcon />
      </CloseButton>
      <In>
        <Top>DuoCards Premium</Top>

        {isChristmasFamily ? (
          <InternalAdOverviewChristmas visibleIndex={visibleIndex} />
        ) : saleOnFirstPaywall ? (
          <InternalAdFirstPaywallSale visibleIndex={visibleIndex} />
        ) : (
          <InternalAdOverviewBenefits visibleIndex={visibleIndex} />
        )}

        {isBlackFriday && <Title>BLACK FRIDAY -60%</Title>}

        <div>
          {(saleId || saleOnFirstPaywall) && <SPriceExtra plan={saleOnFirstPaywall ? storeIdYear : storeIdFamily} />}

          {subscriptions && (
            <>
              {subscriptions[storeIdYear].isTrial ? (
                <Trans parent={FreeAndPrice}>
                  7 days free, then <TransVariable text={subscriptions[storeIdYear]?.price + " / " + t("1 Year")} />
                </Trans>
              ) : (
                <Price>
                  {subscriptions[storeIdYear]?.price} / <Trans>1 Year</Trans>
                </Price>
              )}
            </>
          )}
        </div>
        {!viewer?.installReferrer && <SSubscriptionPromocode onSuccess={onClose} />}
        <SButton variant="contained" color="primary" onClick={order}>
          {subscriptions && !!saleId && <Trans>Subscribe</Trans>}
          {subscriptions && !saleId && <Trans>Start free trial</Trans>}
          {!subscriptions && <Trans>Subscribtion is not available</Trans>}
        </SButton>
      </In>
    </Wrap>
  );
};

export default InternAdOverview;
